import { APP_VERSION_HEADER_KEY } from '@shared/constants'
import { ConfiguredMiddleware } from 'wretch'

import { APP_NEEDS_UPDATE_EVENT } from '~/constants/events'

export const appVersionMiddleware: ConfiguredMiddleware =
  (next) => (url, opts) => {
    return next(url, opts).then((res) => {
      const latestVersion = res.headers.get(APP_VERSION_HEADER_KEY)
      const currVersion = import.meta.env.VITE_APP_VERSION
      const isTestOrDev =
        import.meta.env.VITE_ENV === 'development' ||
        import.meta.env.VITE_ENV === 'test'
      const isServerError = res.status >= 500
      // For 304s the old x-app-version header value is served in the response. We want to filter these errors
      // We check for 200 - 299 instead of just !== 304 to be more lax with the checks
      const isNotCachedRes = res.status >= 200 && res.status <= 299

      // Skip dispatch if server error because it is not possible tell whether version is mismatched
      if (
        !isTestOrDev &&
        isNotCachedRes &&
        !isServerError &&
        latestVersion !== currVersion
      ) {
        window.dispatchEvent(new Event(APP_NEEDS_UPDATE_EVENT))
      }

      return res
    })
  }
