import {
  GetRecordingDto,
  UpdateRecordingTitleDto,
} from '@shared/types/recording'
import {
  CreateSummaryDto,
  GetSummaryDto,
  UpdateFavouriteDto,
} from '@shared/types/summary'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import { useToast } from '~/hooks/useToast'
import { api } from '~/utils/api'

export const useUpdateRecordingMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      recordingId,
      body,
    }: {
      recordingId: number
      body: UpdateRecordingTitleDto
    }) =>
      await api
        .url(`/recordings/${recordingId}`)
        .patch(body)
        .json<GetRecordingDto>(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.all(),
      })
      onSuccess?.()
    },
    onError,
  })
}

export const useDeleteRecordingMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (recordingId: number) =>
      await api.url(`/recordings/${recordingId}`).delete().res(),
    mutationKey: queryKeys.recordings.all(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.all(),
      })
      onSuccess?.()
    },
    onError,
  })
}

export const useDeleteSummaryMutation = ({
  recordingId,
  summaryId,
  onSuccess,
  onError,
}: {
  recordingId: number
  summaryId: number
  onSuccess?: () => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async () =>
      await api.url(`/summaries/${summaryId}`).delete().res(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.recording(recordingId).all(),
      })
      toast({ status: 'success', description: 'Summary deleted' })
      onSuccess?.()
    },
    onError,
  })
}

export const useCreateSummaryMutation = ({
  recordingId,
  onSuccess,
  onError,
}: {
  recordingId: number
  onSuccess?: () => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (
      req: Pick<CreateSummaryDto, 'description' | 'headers' | 'writingStyle'>,
    ) =>
      await api
        .url(`/summaries`)
        .post({
          recordingId,
          ...req,
        })
        .json<GetSummaryDto>(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.recording(recordingId).all(),
      })
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.previousSummaries(),
      })
      onSuccess?.()
    },
    onError,
  })
}

export const useUpdateFavouriteMutation = ({
  summaryId,
  onSuccess,
  onError,
}: {
  summaryId: number
  onSuccess?: () => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (req: UpdateFavouriteDto) =>
      await api.url(`/summaries/${summaryId}`).patch(req).json<GetSummaryDto>(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.previousSummaries(),
      })
      await queryClient.invalidateQueries({
        queryKey: queryKeys.recordings.favouriteSummaries(),
      })
      onSuccess?.()
    },
    onError,
  })
}
