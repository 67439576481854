import {
  EmailSignInDto,
  EmailVerifyOtpDto,
  GetUserDto,
  LogoutUrlResponse,
  SignInResponse,
} from '@shared/types/auth'
import { useMutation } from '@tanstack/react-query'
import { WretchError } from 'wretch'

import type { VfnStepData } from '~/pages/Login'
import { api } from '~/utils/api'

export const useLogin = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (props: VfnStepData) => void
  onError: (err: WretchError) => void
}) => {
  return useMutation({
    mutationFn: (req: EmailSignInDto) =>
      api.url(`/auth/login`).post(req).json<SignInResponse>(),
    onSuccess,
    onError,
  })
}

export const useLogout = () => {
  const { mutate } = useMutation({
    mutationFn: () => api.url('/auth/logout').post().json<LogoutUrlResponse>(),
    onSuccess: () => {
      window.location.href = '/'
    },
  })
  return {
    logout: mutate,
  }
}

export const useVerifyOtp = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (err: WretchError) => void
}) => {
  return useMutation({
    mutationFn: (req: EmailVerifyOtpDto) =>
      api.url(`/auth/verify`).post(req).json<GetUserDto>(),
    onSuccess,
    onError,
  })
}
