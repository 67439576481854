import { BiChevronDown, BiChevronUp, BiLogOutCircle } from 'react-icons/bi'
import { GrConfigure } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useLogout } from '~/hooks/auth'
import { useMyself } from '~/hooks/useMyself'
import { routes } from '~/routes'

import { NavMenuItem } from './NavMenuItem'

export const UserMenu = () => {
  const { logout } = useLogout()
  const {
    user: { name, email, role },
  } = useMyself()

  const navigate = useNavigate()

  return (
    <Box flexShrink={0}>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={IconButton}
              _active={{}}
              _hover={{}}
              variant="clear"
            >
              <HStack align="center" spacing={2}>
                <Avatar
                  bg="brand.primary.500"
                  w={8}
                  h={8}
                  name={name}
                  getInitials={(name) => name.charAt(0)}
                ></Avatar>
                <VStack align="flex-start" spacing={0}>
                  <Text textStyle="caption-1">{name}</Text>
                </VStack>
                <Icon boxSize={6} as={isOpen ? BiChevronUp : BiChevronDown} />
              </HStack>
            </MenuButton>

            <MenuList zIndex="dropdown" py={0}>
              <Box px={4} py={3}>
                <VStack align="flex-start" textStyle="body-1">
                  <Text as="strong">{name}</Text>
                  <Text>{email}</Text>
                </VStack>
              </Box>
              <Divider />
              {role === 'admin' && (
                <NavMenuItem
                  icon={GrConfigure}
                  onClick={() => navigate(routes.appConfig)}
                >
                  App Config
                </NavMenuItem>
              )}
              <NavMenuItem icon={BiLogOutCircle} onClick={() => logout()}>
                Logout
              </NavMenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}
