import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const LoginImageSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width={394}
    height={293}
    viewBox="0 0 394 293"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.57 45.261a5 5 0 0 1 5-5h100.847a5 5 0 0 1 5 5v210.557a5 5 0 0 1-5 5H5.57a5 5 0 0 1-5-5V45.261Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={1.021}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.658 168.486h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M33.658 168.486h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.658 177.51h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M33.658 177.51h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.658 186.539h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M33.658 186.539h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.164 195.562h33.659-33.659Z"
      fill="#fff"
    />
    <path d="M39.164 195.562h33.659" stroke="#000" strokeWidth={1.021} />
    <path
      d="m32.617 210.042.876 1.777 1.957.285-1.416 1.382.335 1.952-1.752-.921-1.757.921.336-1.952-1.417-1.382 1.962-.285.876-1.777ZM44.304 210.042l.875 1.777 1.962.285-1.421 1.382.335 1.952-1.751-.921-1.752.921.335-1.952-1.416-1.382 1.957-.285.876-1.777Z"
      fill="#000"
    />
    <path
      d="m55.998 210.042.876 1.777 1.957.285-1.417 1.382.336 1.952-1.752-.921-1.752.921.336-1.952-1.422-1.382 1.962-.285.876-1.777ZM67.69 210.042l.875 1.777 1.957.285-1.416 1.382.335 1.952-1.752-.921-1.751.921.33-1.952-1.416-1.382 1.962-.285.875-1.777ZM79.376 210.042l.876 1.777 1.962.285-1.417 1.382.33 1.952-1.751-.921-1.752.921.335-1.952-1.416-1.382 1.957-.285.876-1.777Z"
      fill="#BFC2C8"
    />
    <rect
      x={35.049}
      y={229.862}
      width={41.892}
      height={11.306}
      rx={5.653}
      fill="#BABECB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282.154 45.261a5 5 0 0 1 5-5h100.847a5 5 0 0 1 5 5v210.557a5 5 0 0 1-5 5H287.154a5 5 0 0 1-5-5V45.261Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={1.021}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.242 168.485h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M315.242 168.485h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.242 177.51h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M315.242 177.51h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.242 186.538h44.675-44.675Z"
      fill="#fff"
    />
    <path d="M315.242 186.538h44.675" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M320.754 195.562h33.659-33.659Z"
      fill="#fff"
    />
    <path d="M320.754 195.562h33.659" stroke="#000" strokeWidth={1.021} />
    <path
      d="m314.201 210.042.876 1.777 1.957.285-1.416 1.382.335 1.952-1.752-.921-1.752.921.331-1.952-1.417-1.382 1.962-.285.876-1.777ZM325.888 210.042l.88 1.777 1.957.285-1.416 1.382.335 1.952-1.756-.921-1.752.921.335-1.952-1.416-1.382 1.957-.285.876-1.777ZM337.579 210.042l.876 1.777 1.962.285-1.422 1.382.336 1.952-1.752-.921-1.752.921.336-1.952-1.417-1.382 1.957-.285.876-1.777Z"
      fill="#000"
    />
    <path
      d="m349.271 210.042.876 1.777 1.957.285-1.416 1.382.335 1.952-1.752-.921-1.751.921.335-1.952-1.421-1.382 1.962-.285.875-1.777ZM360.958 210.042l.876 1.777 1.962.285-1.417 1.382.336 1.952-1.757-.921-1.752.921.335-1.952-1.416-1.382 1.957-.285.876-1.777Z"
      fill="#BFC2C8"
    />
    <rect
      x={316.633}
      y={229.862}
      width={41.892}
      height={11.306}
      rx={5.653}
      fill="#BABECB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.406 15.19a5 5 0 0 1 5-5h124.335a5 5 0 0 1 5 5.001v257.284a5 5 0 0 1-5 5H134.406a5 5 0 0 1-5-5V15.191Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={1.021}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.496 165.587h54.145-54.145Z"
      fill="#fff"
    />
    <path d="M169.496 165.587h54.145" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.496 176.523h54.145-54.145Z"
      fill="#fff"
    />
    <path d="M169.496 176.523h54.145" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.496 187.464h54.145-54.145Z"
      fill="#fff"
    />
    <path d="M169.496 187.464h54.145" stroke="#000" strokeWidth={1.021} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.172 198.4h40.791-40.791Z"
      fill="#fff"
    />
    <path d="M176.172 198.4h40.791" stroke="#000" strokeWidth={1.021} />
    <path
      d="m168.232 215.948 1.061 2.152 2.378.345-1.722 1.677.405 2.362-2.122-1.116-2.122 1.116.406-2.362-1.717-1.677 2.372-.345 1.061-2.152ZM182.403 215.948l1.061 2.152 2.373.345-1.717 1.677.405 2.362-2.122-1.116-2.122 1.116.406-2.362-1.722-1.677 2.377-.345 1.061-2.152ZM196.566 215.948l1.066 2.152 2.373.345-1.717 1.677.405 2.362-2.127-1.116-2.122 1.116.406-2.362-1.717-1.677 2.372-.345 1.061-2.152ZM210.737 215.948l1.061 2.152 2.373.345-1.717 1.677.405 2.362-2.122-1.116-2.122 1.116.406-2.362-1.722-1.677 2.377-.345 1.061-2.152ZM224.902 215.948l1.066 2.152 2.373.345-1.717 1.677.405 2.362-2.127-1.116-2.122 1.116.405-2.362-1.716-1.677 2.372-.345 1.061-2.152Z"
      fill="#000"
    />
    <rect
      x={171.188}
      y={239.967}
      width={50.766}
      height={13.699}
      rx={6.849}
      fill="#BABECB"
    />
    <circle
      cx={337.505}
      cy={107.709}
      r={36.153}
      fill="#E9EAEE"
      stroke="#000"
      strokeWidth={0.453}
    />
    <mask
      id="login-a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={301}
      y={65}
      width={73}
      height={79}
    >
      <path
        d="M373.432 107.709c0 19.842-16.085 35.927-35.927 35.927s-35.927-16.085-35.927-35.927c0-8.107 4.341-33.445 8.871-39.457 6.557-8.701 15.322 3.53 27.056 3.53 19.842 0 35.927 16.085 35.927 35.927Z"
        fill="#ACC7FA"
      />
    </mask>
    <g mask="url(#login-a)">
      <path
        d="M322.134 144.858c-2.868-.452-6.227-4.088-7.548-5.849l8.303 3.774-.755 2.075Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m314.21 144.481 3.696-7.37c.986-.487 4.571 1.548 5.321 3.417 0 0-2.329 9.586-5.321 9.992-2.992.405-18.997.265-27.593-4.471-.774-.347-1.08-1.134-1.288-1.998-.597-.427-.535-1.179-.214-1.532-.418-.791.02-1.513.338-1.995.709-1.077 2.806-.747 4.207-.312 1.027-3.747 4.869-3.786 3.997-2.724-1.797 2.187-1.36 3.912-1.36 3.912s1.611 1.466 7.649 2.651c6.039 1.185 10.568.43 10.568.43Z"
        fill="#BF7657"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#465173"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m290.273 127.474 20.002-.204.292 28.682-20.002.204z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m292.549 128.583 15.473-.158.258 25.285-15.474.158z"
      />
      <path
        d="M293.857 129.324c-.553-.749 1.889-1.888 2.544-2.038.655-.151-.605-1.043-1.265-1.371l9.628-.098c-.799.343-2.04 1.311-.906 1.393 1.133.081 2.878 1.312 2.593 1.986l-12.594.128Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M336.236 117.119c-8.817 2.453-15.233 16.606-21.461 21.701 0 0 6.08 2.264 8.156 4.34-2.451 8.542-3.547 16.596-3.679 20.946 5.85-2.076 16.418-.943 20.38.944 3.171 1.509 10.631.377 13.965-.378 1.184-4.529-.892-15.662-4.718-22.644 2.264-1.321 7.925-2.265 11.7-3.208-2.416-8.907-11.323-19.688-15.474-23.965l-8.869 2.264Z"
        fill="#BABECB"
      />
      <path
        d="M348.879 142.028c2.264-1.321 7.925-2.265 11.7-3.208-2.416-8.907-11.323-19.688-15.474-23.965l-8.869 2.264c-8.817 2.453-15.233 16.606-21.461 21.701 0 0 6.08 2.264 8.156 4.34m25.948-1.132c3.826 6.982 5.902 18.115 4.717 22.644-3.333.755-10.793 1.887-13.964.378-3.962-1.887-14.53-3.02-20.38-.944.252-8.303 4.015-30.105 14.016-43.024m15.611 20.946-3.774 1.887c.943-4.34-.703-15.662-1.835-22.078"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M360.572 138.82c-3.858 2.455-12.467 6.967-15.285 5.284 4.781-1.929 14.681-5.705 15.285-5.284Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M352.777 178.16c.964-2.953-1.282-22.053-5.124-35.476 3.861-2.166 7.824-2.642 9.482-2.642 2.371 12.121.469 26.171.751 38.118.914 2.969 1.275 8.417 0 9.624-.798.754-.986 0-.986-.566-.235.748-.172 1.371-.841 1.371s-.895-1.192-.913-1.581c0 1.142-.42 1.581-1.142 1.581-1.256-.051-.457-3.18-1.227-5.972-.428.821-1.633 2.205-3.026 1.174-.399-.296 2.695-3.284 3.026-5.631Z"
        fill="#BF7657"
      />
      <path
        d="M355.146 187.008c0 1.142-.42 1.581-1.142 1.581-1.256-.051-.457-3.18-1.227-5.972-.428.821-1.633 2.205-3.026 1.174-.399-.296 2.695-3.284 3.026-5.631.964-2.953-1.282-22.053-5.124-35.476 3.861-2.166 7.824-2.642 9.482-2.642 2.371 12.121.469 26.171.751 38.118.914 2.969 1.275 8.417 0 9.624-.798.754-.986 0-.986-.566m-1.754-.21c.018.389.244 1.581.913 1.581s.606-.623.841-1.371m-1.754-.21s.244-1.111 0-4.391m1.754 4.601s.566-3.02 0-4.601"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m346.797 143.16 4.906 21.89c0-1.647-2.642-15.279-3.963-21.89h-.943Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.42 105.792v-14.53h17.738c0 4.15-.377 5.912 0 6.793 1.447-1.07 4.491-2.491 5.095.377.604 2.868-1.332 4.534-2.967 4.723-1.208 6.491-1.184 10.688-.43 12.26-1.509 3.02-6.793 2.516-9.246 1.887v-4.717c-10.115.302-10.19-3.397-10.19-6.793Z"
        fill="#BF7657"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinejoin="round"
      />
      <path
        d="M335.799 112.585c1.322-.06 3.494-.453 4.08-1.542l.071-.157c-.02.054-.044.107-.071.157-.917 1.999-2.904 5.777-4.08 6.071v-4.529ZM332.833 90.892c1.963 5.133 7.737 5.535 10.379 5.095v1.699c1.446-.818 4.529-1.774 5.283.943.755 2.717-2.201 4.403-3.774 4.906-.151.453-.314 3.963-.377 5.661 7.548 2.114 14.97-1.635 17.738-3.774-7.548.566-6.227-11.51-8.303-16.794-1.661-4.227-6.982-3.649-9.435-2.83-1.384-2.328-6.341-7.02-15.096-7.172-8.756-.15-11.071 5.976-11.134 9.058.063 2.39 1.321 7.246 5.85 7.548 4.529.302 7.8-2.767 8.869-4.34Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326.039 82.02c-3.019 0-5.623 4.793-4.718 8.115"
        stroke="#fff"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M328.426 99.445c.097-.41.335-.868.973-.809.594.055.907.563.852 1.157M334.881 99.71c.079-.415.296-.883.935-.853.596.027.932.52.904 1.117M333.918 101.28a1.887 1.887 0 1 0-2.448 2.324M335.1 95.824c0-.783 2.075-.972 2.641.538M330.193 95.824c0-.783-2.264-1.16-2.641.538"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M334.562 108.841c2.346-.65 3.31-3.831 3.5-5.341-1.93 1.716-6.513 3.406-9.802 1.791.4 3.023 3.956 4.199 6.302 3.55Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M333.466 107.784c-1.206-.774-2.364-.147-2.793.263 3.179 1.587 4.775-.148 5.176-1.215-.912-.699-1.969.343-2.383.952Z"
        fill="#fff"
      />
      <circle
        cx={2.453}
        cy={2.453}
        r={2.453}
        transform="matrix(-1 0 0 1 338.494 96.74)"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326.676 99.192a2.453 2.453 0 0 0 3.585 2.177s0-.396.378-.856c.353-.43.825-.566.825-.566a2.453 2.453 0 1 0-4.788-.755ZM343.4 97.305c-1.132.63-3.623 1.887-4.528 1.887"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <circle
      cx={52.861}
      cy={107.709}
      r={36.153}
      fill="#E9EAEE"
      stroke="#000"
      strokeWidth={0.453}
    />
    <mask
      id="login-b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={16}
      y={65}
      width={73}
      height={79}
    >
      <path
        d="M88.788 107.709c0 19.842-16.085 35.927-35.927 35.927s-35.927-16.085-35.927-35.927c0-8.108 4.34-33.445 8.87-39.457 6.557-8.702 15.322 3.53 27.057 3.53 19.842 0 35.927 16.085 35.927 35.927Z"
        fill="#ACC7FA"
      />
    </mask>
    <g mask="url(#login-b)">
      <path
        d="M37.49 144.858c-2.869-.453-6.228-4.088-7.549-5.849l8.303 3.774-.755 2.075Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m29.565 144.481 3.697-7.37c.986-.488 4.57 1.548 5.321 3.417 0 0-2.33 9.586-5.321 9.991-2.992.405-18.997.266-27.593-4.47-.774-.347-1.08-1.135-1.289-1.998-.596-.427-.535-1.179-.214-1.532-.417-.791.02-1.513.338-1.995.71-1.077 2.806-.747 4.207-.312 1.028-3.747 4.87-3.786 3.997-2.724-1.797 2.187-1.36 3.912-1.36 3.912s1.611 1.466 7.65 2.651c6.038 1.185 10.567.43 10.567.43Z"
        fill="#D98F6F"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#465173"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.629 127.474 20.002-.204.292 28.682-20.002.204z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.904 128.583 15.473-.158.258 25.285-15.474.158z"
      />
      <path
        d="M9.213 129.324c-.554-.749 1.889-1.888 2.544-2.039.655-.15-.606-1.042-1.266-1.371l9.629-.098c-.8.344-2.04 1.312-.907 1.394 1.133.081 2.879 1.312 2.594 1.986l-12.594.128Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.591 117.119c-8.817 2.453-15.233 16.606-21.46 21.701 0 0 6.08 2.264 8.156 4.34-2.452 8.541-3.547 16.596-3.679 20.946 5.85-2.076 16.417-.943 20.38.944 3.17 1.509 10.63.377 13.964-.378 1.184-4.529-.892-15.662-4.718-22.644 2.265-1.321 7.926-2.265 11.7-3.208-2.415-8.907-11.322-19.688-15.474-23.966l-8.869 2.265Z"
        fill="#BABECB"
      />
      <path
        d="M64.234 142.028c2.265-1.321 7.926-2.265 11.7-3.208-2.415-8.907-11.322-19.688-15.474-23.966l-8.869 2.265c-8.817 2.453-15.233 16.606-21.46 21.701 0 0 6.08 2.264 8.156 4.34m25.947-1.132c3.826 6.982 5.902 18.115 4.718 22.644-3.334.755-10.794 1.887-13.964.378-3.963-1.887-14.53-3.02-20.38-.944.252-8.303 4.015-30.105 14.016-43.024m15.61 20.946-3.774 1.887c.944-4.34-.703-15.663-1.835-22.078"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.928 138.82c-3.858 2.455-12.467 6.967-15.285 5.284 4.78-1.929 14.68-5.705 15.285-5.284Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.132 178.16c.964-2.953-1.281-22.053-5.123-35.476 3.86-2.166 7.824-2.642 9.481-2.642 2.372 12.121.47 26.17.752 38.118.913 2.969 1.275 8.417 0 9.624-.798.754-.987 0-.987-.567-.235.749-.171 1.372-.84 1.372-.67 0-.896-1.192-.914-1.581 0 1.142-.42 1.581-1.142 1.581-1.255-.051-.456-3.18-1.227-5.972-.428.821-1.633 2.205-3.026 1.174-.399-.296 2.695-3.284 3.026-5.631Z"
        fill="#D98F6F"
      />
      <path
        d="M70.501 187.008c0 1.142-.42 1.581-1.142 1.581-1.255-.051-.456-3.18-1.227-5.972-.428.821-1.633 2.205-3.026 1.174-.399-.296 2.695-3.284 3.026-5.631.964-2.953-1.281-22.053-5.123-35.476 3.86-2.166 7.824-2.642 9.481-2.642 2.372 12.121.47 26.17.752 38.118.913 2.969 1.275 8.417 0 9.624-.798.754-.987 0-.987-.567m-1.754-.209c.018.389.245 1.581.914 1.581s.605-.623.84-1.372m-1.754-.209s.245-1.111 0-4.391m1.754 4.6s.566-3.019 0-4.6"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m62.152 143.16 4.907 21.89c0-1.647-2.642-15.279-3.963-21.89h-.944Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.754 105.471v-14.53s1.51-7.552 10.756-7.741c9.247-.189 10.19 7.36 10.19 7.36 0 3.614-1.144 5.644-2.561 6.744 1.56-.94 3.924-1.682 4.448.808.604 2.868-2.516 3.963-4.152 4.151-1.207 6.492 0 11.26.755 12.832-1.51 3.02-6.793 2.516-9.246 1.887v-4.717c-10.115.302-10.19-3.397-10.19-6.794Z"
        fill="#D98F6F"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinejoin="round"
      />
      <path
        d="M51.133 112.265c1.322-.06 3.494-.454 4.08-1.542l.071-.157c-.02.054-.044.106-.071.157-.916 1.998-2.904 5.776-4.08 6.07v-4.528ZM58.128 98.108V91.88c-1.146-4.152-4.367.188-8.883.188-3.786 0-7.17-3.774-8.667 0-2.466-2.075-3.423-6.416-1.712-7.925 1.712-1.51 3.397-.189 3.586 0-.063-.944.377-3.02 2.642-3.774 2.264-.755 3.082.88 3.208 1.698.314-.692 2.453-2.642 4.529-2.264 2.075.377 2.075 2.138 2.264 2.83.499-.377 1.132-2.076 2.453-1.698 1.321.377 1.2 2.453 1.321 2.83.247-.251 1.386-1.283 2.453-1.132 1.334.189 2.076 2.642 2.076 3.963.453 4.529-.809 8.429-1.496 9.813-1.359-.302-3.083 1.006-3.774 1.698Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.717}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m51.51 89.616.755-1.132M60.568 90.333l1.02-.9M49.526 88.16l-.563-1.239M58.702 87.561l-.227-1.341M45.472 88.204l.603-1.22M54.399 85.244l.894-1.026M42.082 87.81l-.629-1.206M51.133 83.578l-.21-.91M45.536 84.15l-.299-1.327"
        stroke="#fff"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.781 99.445c.098-.41.336-.868.974-.81.594.056.907.564.852 1.158M50.236 99.709c.08-.415.296-.882.936-.853.596.028.931.522.904 1.118M49.273 101.28a1.887 1.887 0 1 0-2.448 2.324M50.455 95.824c0-.784 2.076-.972 2.642.537M45.549 95.824c0-.784-2.265-1.16-2.642.537"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.917 108.841c2.346-.65 3.31-3.831 3.5-5.341-1.93 1.716-6.513 3.406-9.802 1.791.4 3.022 3.956 4.199 6.302 3.55Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.821 107.784c-1.205-.774-2.364-.147-2.793.263 3.18 1.587 4.776-.149 5.177-1.215-.913-.699-1.97.343-2.384.952Z"
        fill="#fff"
      />
      <circle
        cx={2.453}
        cy={2.453}
        r={2.453}
        transform="matrix(-1 0 0 1 53.85 96.739)"
        fill="#fff"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.031 99.192a2.453 2.453 0 0 0 3.586 2.177s0-.397.377-.856c.354-.43.825-.566.825-.566a2.453 2.453 0 1 0-4.788-.755Z"
        fill="#fff"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.756 97.305c-1.132.63-3.623 1.887-4.529 1.887"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={51.019} cy={99.947} r={0.566} fill="#000" />
      <circle cx={44.98} cy={99.947} r={0.566} fill="#000" />
      <path
        d="M49.32 99.38h3.397M42.527 99.38h3.397"
        stroke="#000"
        strokeWidth={0.566}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <circle
      cx={196.721}
      cy={88.766}
      r={48.664}
      fill="#E9EAEE"
      stroke="#000"
      strokeWidth={0.609}
    />
    <mask
      id="login-c"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={148}
      y={31}
      width={98}
      height={107}
    >
      <path
        d="M245.08 88.766c0 26.708-21.651 48.359-48.359 48.359-26.709 0-48.36-21.651-48.36-48.359 0-10.913 5.844-45.018 11.941-53.11 8.825-11.713 20.623 4.75 36.419 4.75 26.708 0 48.359 21.652 48.359 48.36Z"
        fill="#ACC7FA"
      />
    </mask>
    <g mask="url(#login-c)">
      <path
        d="M199.397 88.886c-13.795 1.293-18.825-2.227-22.633-3.665-1.207 5.519-4.958 11.784-6.682 14.227 5.346 1.897 16.885 1.221 21.987.647-8.967 15.002-11.784 32.98-12.071 42.464 6.682-2.371 18.753-1.078 23.279 1.078 3.622 1.724 12.143.431 15.951-.431 0-7.323-5.388-16.957-5.388-25.867 2.586-1.509 9.053-2.587 13.364-3.664-2.759-10.175-12.933-22.49-17.676-27.376l-10.131 2.587Z"
        fill="#465173"
      />
      <path
        d="M213.84 117.339c2.586-1.509 9.053-2.587 13.364-3.664-2.759-10.175-12.933-22.49-17.676-27.376l-10.131 2.587c-13.795 1.293-18.825-2.227-22.633-3.665-1.207 5.519-4.958 11.784-6.682 14.227 5.346 1.897 16.885 1.221 21.987.647m21.771 17.244c0 8.91 5.388 18.544 5.388 25.867-3.808.862-12.329 2.155-15.951.431-4.526-2.156-16.597-3.449-23.279-1.078.287-9.485 3.104-27.462 12.071-42.464m21.771 17.244-4.312 2.156c1.078-4.958-.646-17.239-1.508-23.711m-15.951 4.311c.693-1.207 2.444-3.377 3.233-4.311"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.651 99.232c-.862-.172-.969-3.09-.61-4.742.863.216 2.371.431 3.449.647-.647 1.509-1.977 4.268-2.839 4.095ZM227.42 113.927c-4.517 2.804-14.376 7.931-17.676 6.009 5.597-2.203 16.969-6.489 17.676-6.009Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218.615 159.378c1.101-3.372-1.464-25.191-5.853-40.524 3.88-3.018 8.545-3.581 10.831-3.018 2.709 13.847.535 29.895.858 43.542 1.043 3.392 1.457 9.615 0 10.994-.911.862-1.127 0-1.127-.647-.268.854-.196 1.566-.96 1.566s-1.023-1.361-1.043-1.805c0 1.304-.48 1.805-1.305 1.805-1.434-.057-.521-3.632-1.401-6.822-.489.938-1.866 2.52-3.457 1.342-.456-.338 3.078-3.751 3.457-6.433Z"
        fill="#F5B896"
      />
      <path
        d="M221.321 169.486c0 1.304-.48 1.805-1.305 1.805-1.434-.057-.521-3.632-1.401-6.822-.49.938-1.866 2.52-3.457 1.342-.456-.338 3.078-3.751 3.457-6.433 1.101-3.372-1.464-25.191-5.853-40.524 3.88-3.018 8.545-3.581 10.831-3.018 2.709 13.847.535 29.895.858 43.542 1.043 3.392 1.457 9.615 0 10.994-.911.862-1.127 0-1.127-.647m-2.003-.239c.02.444.279 1.805 1.043 1.805.764 0 .692-.712.96-1.566m-2.003-.239s.279-1.27 0-5.017m2.003 5.256s.647-3.449 0-5.256"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m211.468 118.639 5.604 25.004c0-1.881-3.017-17.453-4.526-25.004h-1.078Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.121 77.893c.518 2.931 7.545 5.963 11.425 7.329 0 4.655-3.018 8.119-4.527 9.484-2.587 0-14.011-5.173-15.089-11.209-.606-3.395.144-23.998 1.293-33.195-1.94-1.51-.646-5.174.001-6.898.517-1.38 3.52 0 4.957.862 2.156-3.88 6.429-2.881 5.174-1.94-2.587 1.94-2.731 4.814-3.234 6.898-1.94 1.293-.646 25.004 0 28.669Z"
        fill="#F5B896"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.411 44.997c-.324.886-2.488.432-3.942-.099-1.454-.53-1.562-1.169-1.324-1.821.238-.653.732-1.072 2.186-.542s3.403 1.575 3.08 2.462Z"
        fill="#F5B896"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.549 47.152c-.323.887-2.487.432-3.941-.098-1.454-.53-1.562-1.169-1.324-1.822.238-.652.732-1.072 2.186-.541 1.454.53 3.402 1.575 3.079 2.461Z"
        fill="#F5B896"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.118 49.308c-.324.886-2.488.432-3.942-.098-1.453-.53-1.562-1.17-1.324-1.822.238-.653.732-1.072 2.186-.542s3.403 1.576 3.08 2.462Z"
        fill="#F5B896"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.049 76.083V59.486h20.262c0 4.742-.431 6.754 0 7.76 1.653-1.222 5.13-2.846 5.82.43.69 3.277-1.521 5.18-3.389 5.396-1.38 7.415-1.353 12.208-.491 14.004-1.725 3.45-7.76 2.875-10.562 2.156v-5.389c-11.554.345-11.64-3.88-11.64-7.76Z"
        fill="#F5B896"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinejoin="round"
      />
      <path
        d="M198.904 83.843c1.511-.069 3.992-.518 4.661-1.76l.081-.18a1.47 1.47 0 0 1-.081.18c-1.047 2.282-3.317 6.597-4.661 6.933v-5.173ZM206.078 82.556c7.975.644 12.358-3.808 15.52-6.251-4.527-7.545-.949-29.92-20.262-28.022-4.24-1.078-13.537-.259-16.814 11.64.884 2.311 4.154 3.65 7.976 4.392.284.055 2.081-7.89 2.371-7.841-.216 6.035.142 8.149 0 8.191 5.477.797 10.85.494 12.502.219v1.94c1.653-.934 5.173-2.027 6.036 1.077.862 3.104-2.515 5.03-4.311 5.605 0 5.601-1.509 7.972-3.018 9.05Z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.592 50.438c-2.731.575-8.407 3.061-9.269 8.407"
        stroke="#fff"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.934 70.645a2.156 2.156 0 1 0-2.797 2.655"
        stroke="#000"
        strokeWidth={0.647}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={198.75} cy={68.492} r={0.647} fill="#000" />
      <circle cx={191.852} cy={68.056} r={0.647} fill="#000" />
      <path
        d="M192.715 65.736c-1.078-.431-3.018.259-3.018 2.155M198.75 66.167c.647-.215 2.026.259 2.371 1.293M197.818 77.325c-.794.862-2.518.862-3.38 0"
        stroke="#000"
        strokeWidth={0.647}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.056 94.928c5.215-1.176 3.234-5.748 1.509-8.623-2.586 3.277-8.55 2.803-11.209 2.156-1.293 2.371-3.104 5.95 0 6.467 3.104.517 4.345-3.88 4.92-5.39 0 1.94 0 6.467 4.78 5.39Z"
        fill="#fff"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M198.964 105.705c1.94-9.053 3.233-12.286 4.311-15.088"
        stroke="#000"
        strokeWidth={0.819}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={0.647}
        cy={0.647}
        r={0.647}
        transform="scale(-1 1) rotate(-17.819 213.777 685.214)"
        fill="#000"
      />
      <circle
        cx={0.647}
        cy={0.647}
        r={0.647}
        transform="scale(-1 1) rotate(-17.819 228.903 682.844)"
        fill="#000"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.276 93.185c0-45.382 36.79-82.172 82.172-82.172 45.383 0 82.173 36.79 82.173 82.172 0 45.383-36.79 82.173-82.173 82.173-45.382 0-82.172-36.79-82.172-82.173Zm82.172-91.987c-50.803 0-91.987 41.184-91.987 91.987 0 47.549 36.076 86.671 82.35 91.489 2.645.275 4.73 2.455 4.73 5.114v52.218a4.907 4.907 0 0 0 9.815 0v-52.218c0-2.659 2.085-4.839 4.731-5.114 46.273-4.818 82.349-43.941 82.349-91.489 0-50.803-41.184-91.987-91.988-91.987Z"
      fill="#465173"
    />
    <path
      d="m209.087 184.674.052.508-.052-.508Zm-19.276 0-.053.508.053-.508Zm9.637-174.172c-45.664 0-82.683 37.018-82.683 82.683h1.022c0-45.1 36.561-81.662 81.661-81.662v-1.021Zm82.684 82.683c0-45.665-37.019-82.683-82.684-82.683v1.021c45.101 0 81.662 36.562 81.662 81.662h1.022Zm-82.684 82.683c45.665 0 82.684-37.018 82.684-82.683h-1.022c0 45.101-36.561 81.662-81.662 81.662v1.021Zm-82.683-82.683c0 45.665 37.019 82.683 82.683 82.683v-1.021c-45.1 0-81.661-36.561-81.661-81.662h-1.022Zm-8.793 0c0-50.521 40.955-91.477 91.476-91.477V.687c-51.085 0-92.498 41.413-92.498 92.498h1.022Zm81.892 90.981c-46.017-4.791-81.892-43.697-81.892-90.98h-1.022c0 47.812 36.277 87.151 82.808 91.996l.106-1.016Zm5.188 57.84v-52.218h-1.022v52.218h1.022Zm8.793-52.218v52.218h1.022v-52.218h-1.022Zm87.08-96.603c0 47.284-35.875 86.19-81.891 90.981l.105 1.016c46.531-4.845 82.808-44.184 82.808-91.997h-1.022ZM199.448 1.708c50.522 0 91.477 40.956 91.477 91.477h1.022c0-51.085-41.413-92.498-92.499-92.498v1.021Zm5.419 188.08c0-2.381 1.871-4.356 4.272-4.606l-.105-1.016c-2.889.301-5.189 2.685-5.189 5.622h1.022Zm-5.418 57.636a5.418 5.418 0 0 0 5.418-5.418h-1.022a4.396 4.396 0 0 1-4.396 4.396v1.022Zm-5.419-5.418a5.419 5.419 0 0 0 5.419 5.418v-1.022a4.396 4.396 0 0 1-4.397-4.396h-1.022Zm-4.272-56.824c2.401.25 4.272 2.225 4.272 4.606h1.022c0-2.937-2.3-5.321-5.188-5.622l-.106 1.016Z"
      fill="#000"
    />
    <path
      d="M194.434 247.271c0-.198.159-.357.357-.357h9.286c.197 0 .357.159.357.357v40.714c0 .198-.16.357-.357.357h-9.286a.356.356 0 0 1-.357-.357v-40.714Z"
      fill="#000"
      stroke="#000"
      strokeWidth={8.171}
    />
  </svg>
))
