import { HStack, VStack } from '@chakra-ui/react'
import { RestrictedGovtMasthead } from '@opengovsg/design-system-react'

import { LoginImageSvgr } from '~/assets/LoginImageSvgr'
import { LoginGridArea } from '~/pages/Login/GridLayout'
import { CurrentLoginStep } from '~/pages/Login/LoginStep'
import { SignInContextProvider } from '~/pages/Login/SignInContext'

export const LoginPage = () => (
  <VStack spacing={0} h="100vh" align="stretch">
    <RestrictedGovtMasthead />
    <HStack
      flex={1}
      align="stretch"
      w="100wh"
      flexWrap="wrap"
      justifyContent="flex-start"
      spacing="0"
      pb={20}
      bgGradient={{
        lg: 'linear(to-r, base.canvas.brand-subtle calc(45% - 4px), white 0)',
      }}
    >
      <VStack w={{ base: '0', md: '100%', lg: '45%' }} justifyContent="center">
        <LoginImageSvgr maxW="100%" aria-hidden />
      </VStack>

      <VStack
        w={{ base: '100%', lg: '55%' }}
        justifyContent="center"
        alignItems="flex-start"
        px={24}
      >
        <LoginGridArea>
          <SignInContextProvider>
            <CurrentLoginStep />
          </SignInContextProvider>
        </LoginGridArea>
      </VStack>
    </HStack>
  </VStack>
)
