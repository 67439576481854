import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { SingleSelect } from '@opengovsg/design-system-react'
import { BannerDto, BannerLevel } from '@shared/types/app-config'

import { useBanner, useUpdateBannerMutation } from '~/hooks/app-config'

export interface BannerFormState {
  bannerLevel: BannerLevel | null
  bannerText: string
}

export const EditBannerTab = () => {
  const banner = useBanner({ suspense: true })

  const { control, handleSubmit, reset } = useForm<BannerFormState>({
    mode: 'onChange',
    defaultValues: {
      bannerLevel: banner?.bannerLevel,
      bannerText: banner?.data ?? '',
    },
  })

  const selectOptions = Object.values(BannerLevel)

  const { mutate: updateBanner } = useUpdateBannerMutation()
  const onSubmit = useCallback(
    async (formData: BannerFormState) => {
      const updateBannerReq: BannerDto = {
        bannerLevel: formData.bannerLevel,
        data: formData.bannerText,
      }
      updateBanner(updateBannerReq)
    },
    [updateBanner],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} w="70%">
        <VStack w="full" align="start" spacing={0}>
          <FormLabel minWidth="120px">Level</FormLabel>
          <Controller
            name={'bannerLevel'}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <SingleSelect
                  name="bannerLevel"
                  items={selectOptions}
                  value={value || ''}
                  onChange={onChange}
                />
              )
            }}
          />
        </VStack>
        <VStack w="full" align="start" spacing={0}>
          <FormLabel minWidth="120px">Text</FormLabel>
          <Controller
            name={'bannerText'}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <Textarea
                    fontSize="0.875rem"
                    value={value}
                    placeholder={'Add banner text'}
                    onChange={onChange}
                  />
                </FormControl>
              )
            }}
          />
        </VStack>
        <HStack w="full">
          <Spacer />
          <Button
            variant="link"
            onClick={() => reset({ bannerLevel: null, bannerText: '' })}
          >
            Clear Banner
          </Button>
          <Button type="submit">Apply Changes</Button>
        </HStack>
      </VStack>
    </form>
  )
}
