import {
  BiCalendarAlt,
  BiClipboard,
  BiPencil,
  BiStopwatch,
  BiTrash,
} from 'react-icons/bi'
import { IoEllipsisHorizontal } from 'react-icons/io5'
import { MdFormatListBulleted } from 'react-icons/md'
import { SlNotebook } from 'react-icons/sl'
import { generatePath, Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Badge, IconButton } from '@opengovsg/design-system-react'
import {
  GetRecordingWithoutSummaryDto,
  RecordingStatus,
} from '@shared/types/recording'
import { fmtDateTime } from '@shared/utils/fmtDatetime'
import { useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import {
  useDeleteRecordingMutation,
  useUpdateRecordingMutation,
} from '~/hooks/recordings'
import { useToast } from '~/hooks/useToast'
import { DeleteRecordingModal } from '~/pages/Recordings/modals/DeleteRecordingModal'
import { RenameRecordingModal } from '~/pages/Recordings/modals/RenameRecordingModal'

import { fmtTimeBetween } from './RecordingCard.helpers'

export type RecordingCardProps = {
  recording: GetRecordingWithoutSummaryDto
}

export const RecordingCard = ({ recording }: RecordingCardProps) => {
  const {
    id: recordingId,
    title,
    createdAt,
    startTime,
    endTime,
    transcript,
    hasSummary,
    status,
  } = recording
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure()
  const {
    isOpen: isRenameModalOpen,
    onClose: onRenameModalClose,
    onOpen: onRenameModalOpen,
  } = useDisclosure()
  const navigate = useNavigate()

  const toast = useToast()
  const queryClient = useQueryClient()

  const { mutateAsync: deleteRecording, isPending: isDeleteRecordingLoading } =
    useDeleteRecordingMutation({
      onSuccess: async () => {
        toast({ status: 'success', description: 'Session deleted!' })
        onDeleteModalClose()
        await queryClient.invalidateQueries({
          queryKey: queryKeys.recordings.all(),
        })
      },
      onError: () =>
        toast({
          status: 'error',
          description: 'Session could not be deleted. Please try again.',
        }),
    })

  const { mutateAsync: updateRecording, isPending: isUpdateRecordingLoading } =
    useUpdateRecordingMutation({
      onSuccess: async () => {
        toast({
          status: 'success',
          description: 'Successfully renamed recording!',
        })
        onRenameModalClose()
        await queryClient.invalidateQueries({
          queryKey: queryKeys.recordings.all(),
        })
      },
      onError: () =>
        toast({
          status: 'error',
          description: 'Session could not be renamed. Please try again.',
        }),
    })
  return (
    <Box position="relative" width="full" display="flex" p={0}>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="reverse"
          icon={<IoEllipsisHorizontal />}
          aria-label={'card-options'}
          position="absolute"
          right={3}
          top={3}
          isDisabled={
            status !== RecordingStatus.READY &&
            status !== RecordingStatus.FAILED
          }
        />
        <MenuList>
          <MenuItem
            icon={<BiPencil fontSize={'1.25em'} />}
            onClick={onRenameModalOpen}
          >
            Rename session
          </MenuItem>
          <MenuItem
            icon={<SlNotebook fontSize={'1.25em'} />}
            onClick={() => navigate(`/recordings/${recordingId}`)}
          >
            Summaries
          </MenuItem>
          <MenuItem
            color={'utility.feedback.critical'}
            icon={<BiTrash fontSize={'1.25em'} />}
            onClick={onDeleteModalOpen}
          >
            Delete session
          </MenuItem>
        </MenuList>
      </Menu>

      <VStack
        p={4}
        backgroundColor="white"
        boxShadow="sm"
        justify={'flex-start'}
        align={'flex-start'}
        alignItems="start"
        width="full"
        rounded={'md'}
        _hover={{
          boxShadow: transcript?.trim() ? 'md' : 'sm',
          cursor: 'pointer',
        }}
        onClick={() =>
          transcript?.trim() &&
          navigate(
            `/${generatePath('recordings/:id', {
              id: recordingId.toString(),
            })}`,
          )
        }
      >
        <Box display="flex" width="full" pr={8}>
          <Link
            as={RouterLink}
            textStyle={'h6'}
            variant="plain"
            to={`/${generatePath('recordings/:id', {
              id: recordingId.toString(),
            })}`}
            overflow="clip"
            noOfLines={3}
            onClick={(e) => e.stopPropagation()}
          >
            {title}
          </Link>
          <HStack pl={2} align={'flex-start'}>
            <Badge colorScheme={'teal'} variant="subtle">
              <HStack spacing={1}>
                <BiClipboard />
                <Text>Transcript</Text>
              </HStack>
            </Badge>
            {hasSummary && (
              <Badge colorScheme={'brand.primary'} variant="subtle">
                <HStack spacing={1}>
                  <MdFormatListBulleted />
                  <Text>Summary</Text>
                </HStack>
              </Badge>
            )}
          </HStack>
        </Box>

        <HStack
          gap={4}
          textStyle="body-2"
          color={
            status === RecordingStatus.READY
              ? 'grey.500'
              : 'interaction.support.disabled-content'
          }
        >
          <HStack spacing={0.5} align={'center'}>
            <BiCalendarAlt /> <Text>{fmtDateTime(createdAt)}</Text>
          </HStack>
          {startTime && endTime && (
            <HStack spacing={0.5} align={'center'}>
              <BiStopwatch /> <Text>{fmtTimeBetween(startTime, endTime)}</Text>
            </HStack>
          )}
        </HStack>
        <Divider my={'8px'} />
        <Text noOfLines={2} wordBreak="break-word">
          {transcript}
        </Text>
      </VStack>
      <DeleteRecordingModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onDelete={() => deleteRecording(recordingId)}
        isLoading={isDeleteRecordingLoading}
      />
      <RenameRecordingModal
        isOpen={isRenameModalOpen}
        onClose={onRenameModalClose}
        onUpdate={(name: string) =>
          updateRecording({ body: { title: name }, recordingId })
        }
        isLoading={isUpdateRecordingLoading}
        initialName={title}
      />
    </Box>
  )
}
