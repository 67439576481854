import { Link as RouterLink } from 'react-router-dom'
import { Flex, HStack } from '@chakra-ui/react'

import { AppLogo } from '~/components/AppLogo'
import { routes } from '~/routes'

import { UserMenu } from './UserMenu'

export const Navbar = () => {
  return (
    <Flex
      flexDir="row"
      pl="1rem"
      h="3rem"
      justifyContent="space-between"
      borderBottom="1px"
      borderBottomColor={'base.divider.medium'}
      bgColor={'base.canvas.default'}
      w="full"
    >
      <HStack spacing={8} flexShrink={0}>
        <RouterLink to={routes.index}>
          <AppLogo width={32} />
        </RouterLink>
      </HStack>
      <HStack spacing={2} pr={2}>
        <UserMenu />
      </HStack>
    </Flex>
  )
}
