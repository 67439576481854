import {
  APP_VERSION_ERROR_MSG,
  APP_VERSION_HEADER_KEY,
} from '@shared/constants'
import wretch from 'wretch'

import {
  UNAUTHORIZED_EVENT,
  UPDATE_FAILED_DUE_TO_VERSION_MISMATCH_EVENT,
} from '~/constants/events'
import { isNestJsErrorJson } from '~/utils/wretch'

import { appVersionMiddleware } from './app-version.middleware'

const UNAUTHORIZED_STATUS_CODE = 401
const PREEXISTING_CONDITIONS_FAILED_CODE = 412

/**
 * Default API client pointing to backend.
 * Automatically catches 403 errors and invalidates authentication state.
 */
export const api = wretch('/api')
  .catcher(UNAUTHORIZED_STATUS_CODE, () => {
    window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
  })
  .catcher(PREEXISTING_CONDITIONS_FAILED_CODE, (e) => {
    // this error will only be thrown in NON-test or development environments
    if (isNestJsErrorJson(e.json) && e.json.message === APP_VERSION_ERROR_MSG) {
      window.dispatchEvent(
        new Event(UPDATE_FAILED_DUE_TO_VERSION_MISMATCH_EVENT),
      )
    }

    throw e
  })
  .errorType('json')
  .middlewares([appVersionMiddleware])
  .headers({ [APP_VERSION_HEADER_KEY]: import.meta.env.VITE_APP_VERSION })
