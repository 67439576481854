import { useState } from 'react'
import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export const RenameRecordingModal = ({
  isOpen,
  onClose,
  onUpdate,
  isLoading,
  initialName,
}: {
  isOpen: boolean
  onClose: () => void
  onUpdate: (name: string) => void
  isLoading: boolean
  initialName: string
}) => {
  const [name, setName] = useState(initialName)
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'md'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename session</ModalHeader>
        <ModalBody>
          <Input
            value={name}
            onChange={(v) => setName(v.target.value)}
            maxLength={60}
          />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="clear" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={() => onUpdate(name)} isLoading={isLoading}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
