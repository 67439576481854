import { appConfig } from './app-config'
import { recordings } from './recordings'
import { users } from './users'

// Reference: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
export const queryKeys = {
  appConfig,
  users,
  recordings,
}
