import { BannerDto } from '@shared/types/app-config'
import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import { api } from '~/utils/api'

export const useBanner = ({ suspense }: { suspense: boolean }) => {
  const { data } = useQuery({
    queryKey: queryKeys.appConfig.banner(),
    queryFn: () => api.url(`/app-config/banner`).get().json<BannerDto>(),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    throwOnError: suspense,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data
}
