import { HStack, Stack, Text } from '@chakra-ui/react'

import { EmailLoginForm } from '../EmailLogin'

export const InitialLoginStep = () => {
  return (
    <Stack gap="2rem" direction="column" width="100%">
      <HStack>
        <Text textStyle="h3" color="base.content.strong">
          Scribe
        </Text>
      </HStack>
      <EmailLoginForm />
    </Stack>
  )
}
