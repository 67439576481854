import { useForm } from 'react-hook-form'
import { FormControl, Stack } from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import { EmailSignInDto } from '@shared/types/auth'
import { WretchError } from 'wretch'

import { useLogin } from '~/hooks/auth/mutations'
import { type VfnStepData } from '~/pages/Login'

interface EmailInputProps {
  onSuccess: (props: VfnStepData) => void
}

export const EmailInput = ({ onSuccess }: EmailInputProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<EmailSignInDto>()

  const { mutate: login, isPending } = useLogin({
    onSuccess,
    onError: (err: WretchError) => setError('email', { message: err.message }),
  })

  const handleSignIn = handleSubmit(({ email }) => {
    return login({ email })
  })

  return (
    <form onSubmit={handleSignIn} noValidate>
      <Stack spacing="1rem">
        <FormControl
          id="email"
          isRequired
          isInvalid={!!errors.email}
          isReadOnly={isPending}
        >
          <FormLabel>Log in with your organisation email address</FormLabel>
          <Input
            placeholder="e.g. jane@nuhs.edu.sg"
            autoFocus
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <Button size="xs" height="2.75rem" type="submit" isLoading={isPending}>
          Get OTP
        </Button>
      </Stack>
    </form>
  )
}
