import { useCallback, useEffect, useState } from 'react'
import { BiMessageSquareError } from 'react-icons/bi'
import { TbRefresh } from 'react-icons/tb'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { MastHead } from '~/components/MastHead'
import {
  APP_NEEDS_UPDATE_EVENT,
  UPDATE_FAILED_DUE_TO_VERSION_MISMATCH_EVENT,
} from '~/constants/events'
import { useToast } from '~/hooks/useToast'

export const AppVersion = () => {
  const toast = useToast()

  const [updateNeeded, setUpdateNeeded] = useState(false)
  const [isContinueBrowsingClicked, setIsContinueBrowsingClicked] =
    useState(false)
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure()

  const handleAppNeedsUpdate = useCallback(() => {
    // Only toggle update needed once
    if (!updateNeeded) {
      setUpdateNeeded(true)
    }

    // Only open modal the very first time the app-version mismatch event happens
    if (!isContinueBrowsingClicked) {
      onOpen()
    }
  }, [isContinueBrowsingClicked, onOpen, updateNeeded])

  const handleRefreshClick = useCallback(() => {
    window.location.reload()
  }, [])

  const handleModalClose = useCallback(() => {
    setIsContinueBrowsingClicked(true)
    onClose()
  }, [onClose])

  const handleAppVersionToast = useCallback(() => {
    toast({
      status: 'error',
      description: `Update failed because a newer version is available. Please refresh the application and try again.`,
    })
  }, [toast])

  useEffect(() => {
    window.addEventListener(APP_NEEDS_UPDATE_EVENT, handleAppNeedsUpdate)
    window.addEventListener(
      UPDATE_FAILED_DUE_TO_VERSION_MISMATCH_EVENT,
      handleAppVersionToast,
    )

    return () => {
      window.removeEventListener(APP_NEEDS_UPDATE_EVENT, handleAppNeedsUpdate)
      window.removeEventListener(
        UPDATE_FAILED_DUE_TO_VERSION_MISMATCH_EVENT,
        handleAppVersionToast,
      )
    }
  }, [handleAppNeedsUpdate, handleAppVersionToast])

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        closeOnOverlayClick={false}
        size="xl"
        motionPreset="none"
        variant="basic"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <VStack spacing={4}>
              <Text fontWeight="Bold">
                A newer version is available. Please refresh the application to
                prevent errors.
              </Text>

              <VStack>
                <Button
                  onClick={handleRefreshClick}
                  leftIcon={<TbRefresh fontSize="1.25em" />}
                >
                  Refresh Application
                </Button>

                <Button
                  onClick={handleModalClose}
                  variant="clear"
                  color="neutral"
                >
                  I will refresh the page myself later
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {updateNeeded && (
        <MastHead
          bgColor={'red.300'}
          iconColor={'critical'}
          icon={BiMessageSquareError}
          text="A newer version is available. Please refresh the application to prevent errors."
          float={true}
        />
      )}
    </>
  )
}
