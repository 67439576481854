import { useMemo } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { BiArrowBack } from 'react-icons/bi'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Icon, Image, Link, Text, VStack } from '@chakra-ui/react'

import ErrorSvg from '~/assets/icons/error.svg'
import NotFoundSvg from '~/assets/icons/notfound.svg'
import { routes } from '~/routes'
import { datadogGetSessionId } from '~/utils/datadog'

type ErrorCardProps = Partial<FallbackProps> & {
  fullscreen?: boolean
  title?: string
  message?: string
  variant?: 'full' | 'inline'
  svg?: string
  isFourOhFour?: boolean
}

export const NotFound = () => {
  return (
    <ErrorCard
      fullscreen={true}
      variant="full"
      message="It seems like you've stumbled upon a missing page."
      svg={NotFoundSvg}
      isFourOhFour={true}
    />
  )
}

export const ErrorCard = ({
  fullscreen,
  title = 'Looks like something went wrong',
  message = `We're working to resolve the issue.`,
  variant = 'full',
  svg = ErrorSvg,
  isFourOhFour = false,
  resetErrorBoundary,
}: ErrorCardProps) => {
  const isFullVariant = variant === 'full'
  const ddSessionId = datadogGetSessionId()
  const navigate = useNavigate()

  const helperActionText = useMemo(() => {
    if (isFourOhFour) {
      return (
        <Text>
          {`Go back to the `}
          <Link onClick={() => navigate(-1)}>previous page</Link>
          {' if the problem persists.'}
        </Text>
      )
    } else {
      return (
        <Text>
          <Link onClick={resetErrorBoundary}>{`Try reloading`}</Link>
          {' if the problem persists.'}
        </Text>
      )
    }
  }, [isFourOhFour, navigate, resetErrorBoundary])

  return (
    <VStack
      w={fullscreen ? '100vw' : '100%'}
      h={fullscreen ? '100vh' : '100%'}
      maxW={isFullVariant ? '600px' : 'auto'}
      mx="auto"
      padding={isFullVariant ? 8 : 0}
      spacing={isFullVariant ? 8 : 0}
      justify="center"
      align={isFullVariant ? 'center' : 'flex-start'}
      textStyle="body-2"
      textAlign="center"
    >
      {isFullVariant && <Image src={svg} alt="Error" />}

      <VStack align="center">
        {isFullVariant ? (
          <>
            <Text textStyle="h2">{title}</Text>
            <Text>{message}</Text>
          </>
        ) : (
          <Text textStyle="subhead-2">{`${title}. ${message}`}</Text>
        )}

        {helperActionText}

        {ddSessionId && (
          <Text>
            Session ID: <code>{ddSessionId}</code>
          </Text>
        )}
      </VStack>

      {fullscreen && (
        <Link
          as={RouterLink}
          variant="plain"
          to={routes.dashboard}
          display="flex"
          alignItems="center"
        >
          <Icon as={BiArrowBack} mr={2} />
          Back to homepage
        </Link>
      )}
    </VStack>
  )
}
