import z from 'zod'

import { createZodDto } from './dto'
import { getSummarySchema } from './summary'

export enum RecordingStatus {
  UPLOADING = 'uploading',
  TRANSCRIBING = 'transcribing',
  READY = 'ready',
  FAILED = 'failed',
}

export const getRecordingForExternalSchema = z.object({
  id: z.number(),
  authorId: z.number(),
  uuid: z.string().uuid(),
  fileName: z.string(),
  title: z.string(),
  status: z.nativeEnum(RecordingStatus),
  hasSummary: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  startTime: z.coerce.date().nullable(),
  endTime: z.coerce.date().nullable(),
})

export class GetRecordingForExternalDto extends createZodDto(
  getRecordingForExternalSchema,
) {}

export const getRecordingSchema = z.object({
  id: z.number(),
  authorId: z.number(),
  uuid: z.string().uuid(),
  fileName: z.string(),
  title: z.string(),
  status: z.nativeEnum(RecordingStatus),
  transcript: z.string().nullable(),
  summaries: z.array(getSummarySchema),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  startTime: z.coerce.date().nullable(),
  endTime: z.coerce.date().nullable(),
})

export class GetRecordingDto extends createZodDto(getRecordingSchema) {}

export const getRecordingWithoutSummarySchema = getRecordingSchema
  .omit({ summaries: true })
  .extend({
    hasSummary: z.boolean(),
  })

export class GetRecordingWithoutSummaryDto extends createZodDto(
  getRecordingWithoutSummarySchema,
) {}

export const createRecordingSchema = z.object({
  title: z.string(),
  fileName: z.string(),
  uuid: z.string().uuid(),
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
})

export class CreateRecordingDto extends createZodDto(createRecordingSchema) {}

export const updateRecordingTitleSchema = z.object({
  title: z.string(),
})

export class UpdateRecordingTitleDto extends createZodDto(
  updateRecordingTitleSchema,
) {}

export const updateRecordingTranscriptSchema = z.object({
  transcript: z.string(),
  outputUuid: z.string().uuid(),
  status: z.nativeEnum(RecordingStatus),
  errorMessage: z.string().optional(),
})

export class UpdateRecordingTranscriptDto extends createZodDto(
  updateRecordingTranscriptSchema,
) {}

export const updateRecordingOutputUuidSchema = z.object({
  uuid: z.string().uuid(),
  outputUuid: z.string().uuid(),
})

export class UpdateRecordingOutputUuidDto extends createZodDto(
  updateRecordingOutputUuidSchema,
) {}

export const updateTranscriptionStartedSchema = z.object({
  uuid: z.string().uuid(),
})

export class UpdateTranscriptionStartedDto extends createZodDto(
  updateTranscriptionStartedSchema,
) {}
