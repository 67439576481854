import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Skeleton } from '@chakra-ui/react'

import { AppLayout } from '~/app/AppLayout'
import { Auth } from '~/app/Auth'
import { ErrorCard, NotFound } from '~/components/ErrorCard'
import { AppVersion } from '~/pages/app-version'
import { AppConfig } from '~/pages/AppConfig/AppConfig'
import { LoginPage } from '~/pages/Login'
import { Recordings } from '~/pages/Recordings'
import { Recording } from '~/pages/Recordings/Recording'
import { routes } from '~/routes'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<ErrorCard />}>
        <Suspense fallback={<Skeleton w="100vw" h="100vh" />}>
          <AppVersion />

          <Auth>
            <Routes>
              <Route index element={<Navigate to={routes.recordings} />} />
              <Route path={routes.recordings} element={<Recordings />} />
              {/*  <Route index element={<Recordings />} />*/}
              {/*  <Route path="*" element={<Navigate to="" />} />*/}
              {/*</Route>*/}
              <Route path={routes.appConfig} element={<AppLayout />}>
                <Route index element={<AppConfig />} />
                <Route path="*" element={<Navigate to="" />} />
              </Route>

              {/* Login routes */}
              <Route path={routes.login} element={<LoginPage />} />

              <Route path={`${routes.recordings}/`} element={<Recordings />} />
              <Route
                path={`${routes.recordings}/:id`}
                element={<Recording />}
              />
              {/* Error routes */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Auth>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
