import dayjs from 'dayjs'

export const fmtTimeBetween = (
  startDate: Date | null,
  endDate: Date | null,
): string | null => {
  if (!startDate || !endDate) return ''
  const minDiff = dayjs(endDate).diff(startDate, 'minute')

  if (minDiff === 0) {
    const secDiff = dayjs(endDate).diff(startDate, 'second')
    return `${secDiff} sec`
  }

  return `${minDiff} min`
}
