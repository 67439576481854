export const AppLogo = ({ width }: { width: number }) => {
  return (
    <svg
      width={width * 4}
      height="33"
      viewBox="0 0 112 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.045 25.6004C104.703 25.6004 103.543 25.3214 102.567 24.7635C101.596 24.1997 100.849 23.4034 100.326 22.3747C99.8033 21.3401 99.5417 20.1225 99.5417 18.7218C99.5417 17.3443 99.8033 16.1354 100.326 15.095C100.855 14.0489 101.593 13.2352 102.541 12.654C103.488 12.0669 104.601 11.7734 105.88 11.7734C106.705 11.7734 107.484 11.9071 108.216 12.1745C108.954 12.436 109.605 12.8429 110.169 13.395C110.739 13.9472 111.186 14.6504 111.512 15.5048C111.837 16.3534 112 17.3647 112 18.5387V19.5064H101.024V17.3792H108.975C108.969 16.7747 108.838 16.2371 108.582 15.7663C108.327 15.2898 107.969 14.9149 107.51 14.6417C107.057 14.3685 106.528 14.232 105.923 14.232C105.278 14.232 104.712 14.3889 104.223 14.7027C103.735 15.0108 103.354 15.4176 103.081 15.9233C102.814 16.4231 102.677 16.9724 102.672 17.571V19.428C102.672 20.2068 102.814 20.8752 103.099 21.4331C103.384 21.9853 103.782 22.4096 104.293 22.706C104.805 22.9966 105.403 23.1419 106.089 23.1419C106.548 23.1419 106.964 23.078 107.336 22.9501C107.708 22.8164 108.03 22.6217 108.303 22.366C108.577 22.1102 108.783 21.7935 108.922 21.4157L111.869 21.747C111.683 22.5258 111.329 23.2058 110.806 23.787C110.288 24.3624 109.626 24.81 108.818 25.1296C108.01 25.4435 107.086 25.6004 106.045 25.6004Z"
        fill="black"
      />
      <path
        d="M85.2839 25.3411V7.48633H88.4398V14.1644H88.5706C88.7333 13.8389 88.9629 13.4931 89.2593 13.127C89.5558 12.755 89.9568 12.4382 90.4624 12.1767C90.9681 11.9093 91.6132 11.7757 92.3979 11.7757C93.4324 11.7757 94.3653 12.0401 95.1964 12.569C96.0333 13.0921 96.6959 13.868 97.1841 14.8967C97.6782 15.9197 97.9252 17.1751 97.9252 18.663C97.9252 20.1334 97.684 21.383 97.2016 22.4118C96.7192 23.4405 96.0624 24.2252 95.2313 24.7657C94.4001 25.3062 93.4586 25.5765 92.4066 25.5765C91.6394 25.5765 91.003 25.4486 90.4973 25.1929C89.9917 24.9371 89.5848 24.6291 89.2768 24.2687C88.9746 23.9026 88.7392 23.5568 88.5706 23.2313H88.3875V25.3411H85.2839ZM88.3788 18.6455C88.3788 19.5115 88.5009 20.27 88.745 20.921C88.9949 21.5719 89.3523 22.0805 89.8173 22.4467C90.2881 22.807 90.8577 22.9872 91.5261 22.9872C92.2235 22.9872 92.8076 22.8012 93.2784 22.4292C93.7492 22.0514 94.1037 21.5371 94.342 20.8861C94.5861 20.2293 94.7082 19.4825 94.7082 18.6455C94.7082 17.8144 94.589 17.0763 94.3507 16.4311C94.1124 15.786 93.7579 15.2803 93.2871 14.9142C92.8163 14.548 92.2293 14.3649 91.5261 14.3649C90.8519 14.3649 90.2794 14.5422 89.8086 14.8967C89.3378 15.2513 88.9804 15.7482 88.7363 16.3875C88.498 17.0269 88.3788 17.7795 88.3788 18.6455Z"
        fill="black"
      />
      <path
        d="M79.2342 25.3399V11.9489H82.3901V25.3399H79.2342ZM80.8209 10.0483C80.321 10.0483 79.8909 9.88265 79.5306 9.55136C79.1702 9.21426 78.9901 8.81032 78.9901 8.33954C78.9901 7.86295 79.1702 7.45901 79.5306 7.12772C79.8909 6.79062 80.321 6.62207 80.8209 6.62207C81.3265 6.62207 81.7566 6.79062 82.1111 7.12772C82.4715 7.45901 82.6517 7.86295 82.6517 8.33954C82.6517 8.81032 82.4715 9.21426 82.1111 9.55136C81.7566 9.88265 81.3265 10.0483 80.8209 10.0483Z"
        fill="black"
      />
      <path
        d="M69.9913 25.3387V11.9477H73.0514V14.1795H73.1909C73.435 13.4065 73.8535 12.8108 74.4463 12.3923C75.0449 11.968 75.7279 11.7559 76.4951 11.7559C76.6694 11.7559 76.8641 11.7646 77.0792 11.782C77.3 11.7936 77.4831 11.814 77.6284 11.843V14.7462C77.4947 14.6997 77.2826 14.659 76.992 14.6241C76.7072 14.5834 76.4311 14.5631 76.1638 14.5631C75.5884 14.5631 75.0711 14.6881 74.6119 14.938C74.1586 15.1821 73.8012 15.5221 73.5396 15.958C73.2781 16.3939 73.1473 16.8967 73.1473 17.4662V25.3387H69.9913Z"
        fill="black"
      />
      <path
        d="M62.1543 25.6004C60.8175 25.6004 59.6696 25.3069 58.7106 24.7199C57.7575 24.1329 57.0222 23.3221 56.505 22.2875C55.9935 21.2471 55.7378 20.0499 55.7378 18.6956C55.7378 17.3356 55.9993 16.1354 56.5224 15.095C57.0455 14.0489 57.7836 13.2352 58.7368 12.654C59.6958 12.0669 60.8292 11.7734 62.1369 11.7734C63.2237 11.7734 64.1856 11.974 65.0226 12.375C65.8653 12.7702 66.5366 13.3311 67.0365 14.0576C67.5363 14.7783 67.8211 15.621 67.8908 16.5859H64.8744C64.7523 15.9407 64.4617 15.4031 64.0026 14.973C63.5492 14.5371 62.9419 14.3191 62.1805 14.3191C61.5353 14.3191 60.9686 14.4935 60.4804 14.8422C59.9922 15.1851 59.6115 15.6792 59.3384 16.3243C59.071 16.9694 58.9373 17.7425 58.9373 18.6433C58.9373 19.5558 59.071 20.3405 59.3384 20.9972C59.6057 21.6482 59.9806 22.1509 60.463 22.5055C60.9512 22.8542 61.5237 23.0286 62.1805 23.0286C62.6454 23.0286 63.061 22.9414 63.4272 22.767C63.7991 22.5868 64.1101 22.3282 64.36 21.9911C64.6099 21.654 64.7814 21.2442 64.8744 20.7618H67.8908C67.8153 21.7092 67.5363 22.5491 67.0539 23.2814C66.5715 24.0079 65.9147 24.5775 65.0836 24.9901C64.2525 25.397 63.276 25.6004 62.1543 25.6004Z"
        fill="black"
      />
      <path
        d="M50.7036 12.3946C50.6223 11.6332 50.2793 11.0404 49.6749 10.6161C49.0762 10.1918 48.2974 9.97968 47.3384 9.97968C46.6642 9.97968 46.0859 10.0814 45.6035 10.2848C45.1211 10.4882 44.752 10.7643 44.4963 11.113C44.2406 11.4618 44.1098 11.8599 44.104 12.3074C44.104 12.6794 44.1883 13.002 44.3568 13.2751C44.5312 13.5483 44.7666 13.7808 45.063 13.9726C45.3594 14.1586 45.6878 14.3155 46.0481 14.4434C46.4085 14.5712 46.7717 14.6788 47.1379 14.7659L48.8118 15.1844C49.486 15.3413 50.134 15.5535 50.7559 15.8208C51.3836 16.0882 51.9445 16.4253 52.4385 16.8321C52.9384 17.239 53.3336 17.7301 53.6242 18.3055C53.9148 18.8809 54.0601 19.5551 54.0601 20.3281C54.0601 21.3743 53.7928 22.2955 53.258 23.0918C52.7233 23.8822 51.9503 24.5012 50.939 24.9487C49.9335 25.3904 48.7159 25.6113 47.2861 25.6113C45.897 25.6113 44.691 25.3963 43.6681 24.9662C42.651 24.5361 41.8547 23.9084 41.2793 23.083C40.7097 22.2577 40.4017 21.2522 40.3552 20.0666H43.5373C43.5838 20.6885 43.7756 21.2057 44.1127 21.6184C44.4498 22.0311 44.8886 22.3391 45.4292 22.5425C45.9755 22.7459 46.5858 22.8477 47.26 22.8477C47.9632 22.8477 48.5793 22.743 49.1082 22.5338C49.6429 22.3188 50.0614 22.0223 50.3636 21.6446C50.6658 21.261 50.8199 20.8134 50.8257 20.302C50.8199 19.837 50.6833 19.4534 50.4159 19.1512C50.1486 18.8431 49.7737 18.5874 49.2913 18.384C48.8147 18.1747 48.2567 17.9887 47.6174 17.826L45.5861 17.3029C44.1156 16.9251 42.9532 16.3526 42.0988 15.5854C41.2503 14.8124 40.826 13.7866 40.826 12.5079C40.826 11.456 41.1108 10.5347 41.6804 9.74429C42.2557 8.95385 43.0375 8.34067 44.0255 7.90477C45.0136 7.46305 46.1324 7.24219 47.382 7.24219C48.6491 7.24219 49.7592 7.46305 50.7123 7.90477C51.6713 8.34067 52.424 8.94804 52.9703 9.72686C53.5167 10.4999 53.7986 11.3891 53.816 12.3946H50.7036Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9981 32.4982C24.8336 32.4982 31.9962 25.3356 31.9962 16.5001C31.9962 7.66455 24.8336 0.501953 15.9981 0.501953C7.16259 0.501953 0 7.66455 0 16.5001C0 25.3356 7.16259 32.4982 15.9981 32.4982ZM5.35249 11.1286C7.23563 9.22583 9.00904 8.1641 11.3659 7.52845C13.2971 7.0076 16.0814 7.29256 18.0069 8.20813C19.2955 8.82079 20.7339 10.2732 21.4398 11.6744C21.9548 12.6969 22.3916 14.0101 22.3916 14.5364C22.3916 14.697 22.7615 14.9465 23.6204 15.365C25.2491 16.1585 26.9262 17.2862 28.0875 18.369C28.9282 19.1527 29.0336 19.3046 29.0336 19.7313C29.0336 20.3928 28.4507 20.9664 27.7785 20.9664C27.3796 20.9664 27.1624 20.8422 26.4731 20.2201C25.5278 19.3671 24.1692 18.4006 23.1644 17.8666C22.813 17.6797 22.3792 17.8703 22.279 18.2555L22.1696 18.6763C21.4317 21.5138 19.6434 23.7895 17.2735 24.9067C15.8892 25.5594 14.7607 25.7757 13.0264 25.7209C11.716 25.6796 11.4729 25.6326 10.6721 25.2658C8.4086 24.2292 7.15393 22.0434 7.50795 19.7536C7.88654 17.3055 9.7273 15.273 12.384 14.3699C14.149 13.77 16.9178 13.5533 18.8631 13.8629C19.2971 13.932 19.6767 13.9644 19.707 13.935C19.7914 13.853 19.1021 12.4432 18.6988 11.8729C18.1812 11.1412 16.9329 10.2971 15.903 9.98245C15.2759 9.79084 14.6523 9.71066 13.757 9.70614C11.3687 9.69439 9.34406 10.6543 7.11966 12.853C6.01669 13.9431 5.77333 14.1232 5.40323 14.1232C4.79961 14.1232 4.46658 13.9159 4.24593 13.4028C3.97813 12.7796 4.22906 12.2638 5.35249 11.1286ZM19.8868 17.5474C20.0807 16.5244 20.0774 16.5207 18.8084 16.3322C16.7699 16.0294 15.1918 16.0836 13.6778 16.5084C10.7423 17.3319 9.19621 19.7619 10.2866 21.8384C10.8199 22.8541 11.8403 23.3392 13.431 23.3332C16.6662 23.3209 19.2275 21.0254 19.8868 17.5474Z"
        fill="url(#paint0_linear_19661_18348)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19661_18348"
          x1="0"
          y1="0.501953"
          x2="38.5223"
          y2="14.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#568CD8" />
          <stop offset="1" stopColor="#58C1C5" />
        </linearGradient>
      </defs>
    </svg>
  )
}
