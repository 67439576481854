import { Suspense, useCallback, useMemo } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import {
  BiCalendarAlt,
  BiClipboard,
  BiDotsHorizontal,
  BiPen,
  BiPlus,
} from 'react-icons/bi'
import { IoDocumentOutline } from 'react-icons/io5'
import { MdFormatListBulleted } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Menu } from '@opengovsg/design-system-react'
import { RecordingStatus } from '@shared/types/recording'
import { fmtDateTime } from '@shared/utils/fmtDatetime'
import dayjs from 'dayjs'

import { Empty } from '~/components/Empty'
import { Loading } from '~/components/Loading'
import { NavBackLink } from '~/components/NavBackLink'
import { Navbar } from '~/components/Navbar'
import { useRecording, useUpdateRecordingMutation } from '~/hooks/recordings'
import { RenameRecordingModal } from '~/pages/Recordings/modals/RenameRecordingModal'
import { CreateSummaryModal } from '~/pages/Summary/CreateSummaryModal'
import { SummaryCard } from '~/pages/Summary/SummaryCard'
import { ViewTranscriptModal } from '~/pages/Summary/ViewTranscriptModal'

import ClipboardSvg from '../../assets/icons/clipboard.svg'

export const Recording = () => {
  const { id } = useParams()
  const { title, createdAt, transcript, summaries, status } = useRecording(
    Number(id),
  )
  const {
    isOpen: isEditTitleOpen,
    onOpen: onEditTitleOpen,
    onClose: onEditTitleClose,
  } = useDisclosure()

  const {
    isOpen: isViewTranscriptOpen,
    onOpen: onViewTranscriptOpen,
    onClose: onViewTranscriptClose,
  } = useDisclosure()

  const {
    isOpen: isCreateSummaryModalOpen,
    onOpen: onCreateSummaryModalOpen,
    onClose: onCreateSummaryModalClose,
  } = useDisclosure()

  const { mutateAsync: updateTitle, isPending: isUpdateTitleLoading } =
    useUpdateRecordingMutation({ onSuccess: () => onEditTitleClose() })

  const handleEditTitle = useCallback(
    async (title: string) => {
      await updateTitle({ recordingId: Number(id), body: { title } })
    },
    [id, updateTitle],
  )

  const summariesOrdered = useMemo(
    () =>
      summaries.sort((a, b) =>
        dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1,
      ),
    [summaries],
  )

  return (
    <VStack alignItems="left" spacing={0} h={'100vh'}>
      <Navbar />
      <Suspense fallback={<Loading />}>
        <RenameRecordingModal
          isOpen={isEditTitleOpen}
          onClose={onEditTitleClose}
          onUpdate={handleEditTitle}
          isLoading={isUpdateTitleLoading}
          initialName={title}
        />
        <ViewTranscriptModal
          isOpen={isViewTranscriptOpen}
          onClose={onViewTranscriptClose}
          transcript={transcript}
        />
        <VStack
          spacing={6}
          p={0}
          backgroundColor="slate.50"
          align="center"
          overflowY="auto"
          width="full"
          h={'full'}
        >
          <VStack
            spacing={{ base: 0, md: 6 }}
            pt={4}
            width="80%"
            maxW={920}
            align="start"
          >
            <HStack
              width="full"
              justify="space-between"
              px={{ base: 8, md: 0 }}
              pt={{ base: 4, md: 0 }}
              pb={{ base: 3, md: 0 }}
              position={{ base: 'sticky', md: 'relative' }}
              top={{ base: 0, md: 0 }}
              zIndex={2}
              backgroundColor="slate.50"
            >
              <NavBackLink to={`/recordings`} text={'← Back to sessions'} />
            </HStack>
            <VStack
              align="start"
              width="full"
              spacing={6}
              px={{ base: 8, md: 0 }}
              zIndex={1}
              mb={4}
            >
              <VStack justify={'flex-start'} align={'left'} w={'full'}>
                <HStack justify={'space-between'}>
                  <Box display="flex" width="full">
                    <Text textStyle="h6" wordBreak="break-word">
                      {title}
                    </Text>
                    <HStack pl={2}>
                      <Badge colorScheme={'teal'} variant="subtle">
                        <HStack spacing={1}>
                          <BiClipboard />
                          <Text>Transcript</Text>
                        </HStack>
                      </Badge>
                      {summaries.length > 0 && (
                        <Badge colorScheme={'brand.primary'} variant="subtle">
                          <HStack spacing={1}>
                            <MdFormatListBulleted />
                            <Text>Summary</Text>
                          </HStack>
                        </Badge>
                      )}
                    </HStack>
                  </Box>
                  <Button
                    onClick={onCreateSummaryModalOpen}
                    leftIcon={<BiPen />}
                    variant="solid"
                    isDisabled={status !== RecordingStatus.READY}
                  >
                    Generate new summary
                  </Button>
                  <Menu isLazy>
                    <MenuButton
                      as={IconButton}
                      aria-label="More"
                      icon={<BiDotsHorizontal />}
                      variant="outline"
                    />

                    <MenuList>
                      <MenuItem
                        onClick={onEditTitleOpen}
                        icon={<AiOutlineEdit fontSize="1.25em" />}
                        minW={64}
                      >
                        Rename Session
                      </MenuItem>
                      <MenuItem
                        onClick={onViewTranscriptOpen}
                        icon={<IoDocumentOutline fontSize="1.25em" />}
                        minW={64}
                      >
                        View Transcript
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
                <HStack textStyle="body-2" color="grey.500">
                  <BiCalendarAlt /> <Text>{fmtDateTime(createdAt)}</Text>
                </HStack>
              </VStack>

              <VStack w={'full'}>
                {summariesOrdered.map((summary) => (
                  <SummaryCard key={summary.id} summary={summary} />
                ))}
              </VStack>

              {summariesOrdered.length === 0 && (
                <Empty
                  size="lg"
                  svg={ClipboardSvg}
                  title="You have no summaries yet!"
                >
                  <Button
                    variant={'link'}
                    rightIcon={<BiPlus />}
                    size={'md'}
                    onClick={onCreateSummaryModalOpen}
                  >
                    Generate New Summary
                  </Button>
                </Empty>
              )}
            </VStack>
            <CreateSummaryModal
              recordingId={Number(id)}
              isOpen={isCreateSummaryModalOpen}
              onClose={onCreateSummaryModalClose}
            />
          </VStack>
        </VStack>
      </Suspense>
    </VStack>
  )
}
