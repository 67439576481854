import z from 'zod'

import { createZodDto } from './dto'

export enum BannerLevel {
  INFO = 'info',
  ERROR = 'error',
  WARN = 'warn',
}

export enum ConfigType {
  BANNER = 'BANNER',
}

export const bannerSchema = z.object({
  bannerLevel: z.nativeEnum(BannerLevel).nullable(),
  data: z.string().nullable(),
})
export type BannerType = z.infer<typeof bannerSchema>

export class BannerDto extends createZodDto(bannerSchema) {}
