import { KeyboardEventHandler, useCallback, useMemo, useState } from 'react'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { Flex, HStack, IconButton, Input, Text } from '@chakra-ui/react'
import { GetPreviousSummaryDto } from '@shared/types/summary'
import dayjs from 'dayjs'

import { useUpdateFavouriteMutation } from '~/hooks/recordings'

export interface CreateSummaryFormState {
  description: string
  headers: string
  writingStyle: string
}

export const TemplateMenuItem = ({
  summary,
  onClick,
  fixedTitle,
}: {
  summary: GetPreviousSummaryDto & { id?: number }
  onClick: (summary: GetPreviousSummaryDto) => void
  fixedTitle?: string
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(summary.title)

  const { mutate: updateFavourite, isPending: isUpdateFavouriteLoading } =
    useUpdateFavouriteMutation({
      summaryId: summary.id,
    })

  const handleBlur = useCallback(() => {
    setIsEditing(false)
    updateFavourite({ title: title.trim() })
  }, [title, updateFavourite])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        setIsEditing(false)
        updateFavourite({ title: title.trim() })
      }
    },
    [title, updateFavourite],
  )

  const isEditable = useMemo(
    () => fixedTitle === undefined && summary.isFavourite,
    [fixedTitle, summary.isFavourite],
  )

  const handleDoubleClick = useCallback(() => {
    if (isEditable) {
      setIsEditing(true)
    }
  }, [isEditable])

  return (
    <HStack
      fontWeight={'semibold'}
      spacing={0}
      justify={'space-between'}
      w="full"
      _hover={{
        bg: 'brand.primary.100',
        cursor: 'pointer',
        textColor: 'brand.primary.700',
      }}
    >
      <Flex p={2} rounded="md" onClick={() => onClick(summary)} w="150px">
        {!isEditing && (
          <Text
            w={'full'}
            fontSize={'xs'}
            textStyle={'subhead-2'}
            onDoubleClick={handleDoubleClick}
            textOverflow={'ellipsis'}
            whiteSpace="nowrap"
            overflowX="hidden"
          >
            {fixedTitle !== undefined && fixedTitle}
            {fixedTitle === undefined &&
              (isEditable && title.length > 0
                ? title
                : dayjs(summary.createdAt)
                    .tz('Asia/Singapore')
                    .format('DD/MM/YYYY hh:mm A'))}
          </Text>
        )}
        {isEditing && (
          <Input
            value={title}
            maxLength={20}
            onChange={(evt) => setTitle(evt.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            bgColor={'gray.100'}
            focusBorderColor="gray.100"
            pl={0.5}
            fontSize={'0.75rem'}
            sx={{
              height: '1.25rem',
              border: 'none',
              outline: 'none',
              _focus: {
                outline: 'none',
                border: 'none',
                backgroundColor: 'gray.100',
              },
            }}
          />
        )}
      </Flex>
      <Flex>
        {!!summary.id && !summary.isFavourite && (
          <IconButton
            variant="clear"
            aria-label="star template"
            icon={<FaRegStar color="gray.500" />}
            onClick={() => updateFavourite({ isFavourite: true })}
            _hover={{ color: 'brand.primary.500' }}
            color={'gray.500'}
            isLoading={isUpdateFavouriteLoading}
          />
        )}
        {!!summary.id && summary.isFavourite && (
          <IconButton
            variant="clear"
            aria-label="unstar template"
            icon={<FaStar />}
            onClick={() => updateFavourite({ isFavourite: false })}
            isLoading={isUpdateFavouriteLoading}
          />
        )}
      </Flex>
    </HStack>
  )
}

export const DEFAULT_DESCRIPTION = `I am a social worker meeting a client.`
export const DEFAULT_HEADERS =
  'Family Background\nRelationships\nFinancial issues\nMedical issues\nAssessment\nFollow-up actions'
export const DEFAULT_WRITING_STYLE = 'Point form'
