import { useEffect } from 'react'
import { MyselfDto } from '@shared/types/user'
import { useSuspenseQuery } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import { api } from '~/utils/api'
import { datadogSetUser } from '~/utils/datadog'

export const useMyself = () => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.users.myself(),
    queryFn: () => api.url(`/users/me`).get().json<MyselfDto>(),
  })

  // Set datadog user on any `user` change
  useEffect(() => {
    if (data) {
      datadogSetUser(data.id.toString(), data.name, data.email)
    }
  }, [data])

  return { user: { ...data } }
}
