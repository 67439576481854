import 'inter-ui/inter.css'

import { useRef } from 'react'
import { BiMessageSquareError } from 'react-icons/bi'
import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Banner } from '~/components/Banner'
import { MastHead } from '~/components/MastHead'
import { LoginStateProvider } from '~/hooks/auth/LoginStateContext'
import { useToast } from '~/hooks/useToast'
import { theme } from '~/theme'
import { isWretchError } from '~/utils/wretch'

import { AppRouter } from './AppRouter'

const queryClient = new QueryClient()

export const App = () => {
  const ref = useRef<HTMLDivElement>(null)
  const toast = useToast()
  queryClient.setDefaultOptions({
    queries: {
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false,
      throwOnError: (err) => {
        if (!isWretchError(err)) {
          return true
        }
        switch (err.status) {
          case 429:
            toast({
              status: 'error',
              description: 'Too many attempts. Please try again later.',
            })
            break
        }

        return true
      },
    },
  })

  return (
    <LoginStateProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          theme={theme}
          toastOptions={{ portalProps: { containerRef: ref } }}
        >
          {['uat', 'staging', 'vapt'].includes(import.meta.env.VITE_ENV) && (
            <MastHead
              bgColor={'red.200'}
              iconColor={'primary.500'}
              icon={BiMessageSquareError}
              text={`Running in ${import.meta.env.VITE_ENV} environment`}
              float={true}
            />
          )}
          <Banner />
          <AppRouter />
          {/*set toast portal to be above all other portals*/}
          <div ref={ref} />
        </ThemeProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </LoginStateProvider>
  )
}
