import { useCallback } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

import { useToast } from './useToast'

export const useCopyAndToast = () => {
  const [_, copy] = useCopyToClipboard()
  const toast = useToast()

  const copyAndToast = useCallback(
    async (message: string) => {
      const isCopied = await copy(message)
      if (isCopied) {
        toast({ status: 'success', description: 'Copied to clipboard' })
      } else {
        toast({ status: 'error', description: 'Failed to copy to clipboard' })
      }
    },
    [copy, toast],
  )

  return copyAndToast
}
