import { IconType } from 'react-icons'
import { forwardRef, MenuItem, MenuItemProps } from '@chakra-ui/react'

interface NavMenuItemProps extends Omit<MenuItemProps, 'icon'> {
  icon?: IconType
  onClick?: () => void
}

export const NavMenuItem = forwardRef<NavMenuItemProps, 'div'>(
  ({ icon: Icon, onClick, children, ...rest }: NavMenuItemProps, ref) => (
    <MenuItem
      icon={Icon ? <Icon fontSize="1.25em" /> : undefined}
      onClick={onClick}
      minW="280px"
      textStyle="body-2"
      ref={ref}
      {...rest}
    >
      {children || ''}
    </MenuItem>
  ),
)
