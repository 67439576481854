import { extendTheme } from '@chakra-ui/react'
import { theme as ogpTheme } from '@opengovsg/design-system-react'

import { shadows } from './foundations/shadows'
import { components } from './components'
import { layerStyles } from './layerStyles'

export const theme = extendTheme(ogpTheme, {
  shadows,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  components: {
    ...ogpTheme.components,
    ...components,
  },
  layerStyles,
})
