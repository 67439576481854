import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'

import { Loading } from '~/components/Loading'
import { Navbar } from '~/components/Navbar'

export const AppLayout = () => {
  return (
    <Suspense fallback={<Loading />}>
      <VStack align="left">
        <Navbar />
        <Outlet />
      </VStack>
    </Suspense>
  )
}
