import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { BiX } from 'react-icons/bi'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import {
  Box,
  Center,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconButton, Link } from '@opengovsg/design-system-react'
import _ from 'lodash'

import { useRecordings } from '~/hooks/recordings'
import { useMyself } from '~/hooks/useMyself'
import { RecordingCard } from '~/pages/Recordings/RecordingCard'

import backgroundImage from './images/Scribe-BannerBackground.png'
import mswImage from './images/Scribe-MSWs.png'

/** We need this wrapper in its own component otherwise recordingList keeps rerendering. */
const SearchBarWrapper = ({
  setFilter,
}: {
  setFilter: Dispatch<SetStateAction<string>>
}) => {
  // This will only get sent into the filter when they press "enter".
  // We want the controlled component state so that we can hide the clear button when empty.
  const [searchValue, setSearchValue] = useState<string>('')
  return (
    <InputGroup fontSize={'44px'}>
      <InputLeftElement pointerEvents="none" height="44px" width="44px">
        <Box>
          <FaMagnifyingGlass fontSize="16px" height="44px" width="44px" />
        </Box>
      </InputLeftElement>
      <Input
        value={searchValue}
        onChange={(v) => {
          setSearchValue(v.target.value)
          if (v.target.value === '') {
            setFilter('')
          }
        }}
        pl={'44px'}
        maxHeight={'44px'}
        height="44px"
        placeholder="Search keywords"
        fontSize="16px"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setFilter(searchValue)
          }
        }}
      />
      <InputRightElement width="44px" height="44px">
        {searchValue.length > 0 && (
          <IconButton
            height="42px"
            width="42px"
            icon={<BiX />}
            variant="clear"
            aria-label={'clear-search'}
            onClick={() => {
              setFilter('')
              setSearchValue('')
            }}
          />
        )}
      </InputRightElement>
    </InputGroup>
  )
}

export const RecordingsList = () => {
  const {
    user: { name },
  } = useMyself()
  const recordings = useRecordings()
  const [filter, setFilter] = useState<string>('')

  const filteredRecordings = useMemo(() => {
    return filter.trim().length === 0
      ? recordings
      : recordings.filter(
          (r) =>
            r.title.toLowerCase().includes(filter.toLowerCase()) ||
            r.fileName.toLowerCase().includes(filter.toLowerCase()),
        )
  }, [filter, recordings])

  return (
    <VStack
      align="center"
      justify={'start'}
      width="full"
      height="full"
      p={4}
      overflowY="auto"
      backgroundColor="gray.50"
    >
      <VStack
        maxWidth={920}
        width="80%"
        align="left"
        borderRadius="lg"
        boxShadow="sm"
        backgroundImage="linear-gradient(to right, #568CD8, #58C1C5)"
        py={4}
        px={5}
        spacing={1}
        position="relative"
        // overflow="clip"
        zIndex="base"
      >
        <Image
          src={backgroundImage}
          position="absolute"
          bottom={{ base: '-25px', md: 0 }}
          right={{ base: '-85px', md: 0 }}
          zIndex={0}
          height={24}
          width={750}
          minWidth={750}
        />
        <Image
          src={mswImage}
          position="absolute"
          bottom={{ base: '-37px', md: '-12px' }}
          right={{ base: '40px', md: '125px' }}
          zIndex={0}
          height={24}
          width={150}
          minWidth={81}
        />
        <Text
          textStyle="h4"
          color="white"
          maxWidth={{ base: '278px', md: 'full' }}
          lineHeight={9}
        >
          Hello {_.capitalize(name)}!
        </Text>
        <Text
          textStyle="subhead-2"
          color="white"
          zIndex={1}
          maxWidth={{ base: '278px', md: 'full' }}
        >
          Start a session on Scribe! (
          <Link
            href="https://mic.scribe.gov.sg/"
            color={'white'}
            _hover={{
              color: 'white',
              cursor: 'pointer',
            }}
            isExternal
          >
            mic.scribe.gov.sg
          </Link>
          )
        </Text>
      </VStack>
      <VStack maxWidth={920} w={'80%'}>
        {recordings.length > 0 && <SearchBarWrapper setFilter={setFilter} />}
        {filteredRecordings.length > 0 ? (
          filteredRecordings.map((recording) => (
            <RecordingCard key={recording.id} recording={recording} />
          ))
        ) : (
          <Center>
            <Text>
              {recordings.length === 0
                ? "Looks like you don't have any recordings yet!"
                : 'No recordings match the filter!'}
            </Text>
          </Center>
        )}
      </VStack>
    </VStack>
  )
}
