import { useState } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'

import { EditBannerTab } from '~/pages/AppConfig/EditBannerTab'

export const AppConfig = () => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  return (
    <VStack align="stretch" px={10} py={5} spacing={6}>
      <VStack align="stretch" spacing={6}>
        <Text textStyle="h5">App Config</Text>
        <Tabs
          isLazy={true}
          lazyBehavior={'unmount'}
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab>Banner</Tab>
          </TabList>
          <TabPanels py={5}>
            <TabPanel>
              <EditBannerTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  )
}
