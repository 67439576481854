import {
  GetRecordingDto,
  GetRecordingWithoutSummaryDto,
} from '@shared/types/recording'
import {
  GetLastCustomisationDto,
  GetPreviousSummaryDto,
  SummaryStatus,
} from '@shared/types/summary'
import { useSuspenseQuery } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import { api } from '~/utils/api'

export const useRecordings = () => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.recordings.all(),
    queryFn: () =>
      api.url(`/recordings`).get().json<GetRecordingWithoutSummaryDto[]>(),
    refetchInterval: 5000,
  })
  return data
}

export const useRecording = (id: number) => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.recordings.recording(id).all(),
    queryFn: () => api.url(`/recordings/${id}`).get().json<GetRecordingDto>(),
    refetchInterval: (data) => {
      const recording = data.state.data
      if (!recording) {
        return false
      }
      if (
        recording.summaries.every(
          (summary) => summary.status !== SummaryStatus.INITIALISED,
        )
      ) {
        return false
      }
      return 3000
    },
  })
  return data
}

export const useLastCustomisation = () => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.recordings.lastCustomisation(),
    queryFn: () =>
      api
        .url(`/summaries/last-customisation`)
        .get()
        .json<GetLastCustomisationDto>(),
  })
  return data
}

export const usePreviousSummaries = () => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.recordings.previousSummaries(),
    queryFn: () =>
      api
        .url(`/summaries/previous-summaries`)
        .get()
        .json<GetPreviousSummaryDto[]>(),
  })
  return data
}

export const useFavouriteSummaries = () => {
  const { data } = useSuspenseQuery({
    queryKey: queryKeys.recordings.favouriteSummaries(),
    queryFn: () =>
      api
        .url(`/summaries/favourite-summaries`)
        .get()
        .json<GetPreviousSummaryDto[]>(),
  })
  return data
}
