import { datadogRum } from '@datadog/browser-rum'

/**
 * Only initializes datadog RUM if the environment variables are present.
 * i.e. works on staging or production, no-op in local state
 * Also note that these 2 values are not secret - visible to client browser.
 * https://github.com/DataDog/browser-sdk/issues/853#issuecomment-843134166
 */
export function initDatadog() {
  const env = import.meta.env.VITE_ENV
  const isProduction = env === 'production'

  const applicationId = 'a9450655-33da-44fa-b2e0-e8298486cb99'
  const clientToken = 'pub09df7c5701895c6b9374f478a8f0694f'

  if (!applicationId || !clientToken) return
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    proxy: '/api/proxy/datadog',
    service: 'scribe',
    // Standardise name with backend
    env,
    sessionSampleRate: 100,
    // Do not record session if in production
    sessionReplaySampleRate: isProduction ? 0 : 100,
    // Do not track interactions if in production
    trackUserInteractions: !isProduction,
    allowedTracingUrls: [
      'https://staging.scribe.gov.sg', // Staging
      'https://scribe.gov.sg', // Production
    ],
    defaultPrivacyLevel: isProduction ? 'mask' : 'allow',
    version: import.meta.env.VITE_APP_VERSION,
  })

  datadogRum.startSessionReplayRecording()
}

export function datadogSetUser(id: string, name: string, email: string) {
  datadogRum.setUser({
    id: `${import.meta.env.VITE_ENV}-${id}`,
    name,
    email,
  })
}

export function datadogGetUser() {
  return datadogRum.getUser()
}

export function datadogGetSessionId() {
  return datadogRum.getInternalContext()?.session_id
}
