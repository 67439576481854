import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)

export const fmtDateTime = (date: Date | dayjs.Dayjs): string => {
  return dayjs(date).tz('Asia/Singapore').format('DD/MM/YYYY h:mm A')
}

export const fmtMinsBetween = (
  startDate: Date | null,
  endDate: Date | null,
): string | null => {
  if (!startDate || !endDate) return ''
  const diff = dayjs(endDate).diff(startDate, 'minute')

  return `${diff}min`
}
