import { BannerDto } from '@shared/types/app-config'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '~/constants/query-keys'
import { useToast } from '~/hooks/useToast'
import { api } from '~/utils/api'

export const useUpdateBannerMutation = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body: BannerDto): Promise<BannerDto> => {
      return await api.url(`/app-config/banner`).patch(body).res<BannerDto>()
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.appConfig.banner(),
      })
      toast({
        description: 'Successfully updated banner.',
        status: 'success',
      })
    },
    onError: async () => {
      toast({
        description: 'Error updating banner. Please try again.',
        status: 'error',
      })
    },
  })
}
