export const users = {
  /**
   * All users.
   * ```
   * ['users']
   * ```
   */
  all: () => ['users'] as const,

  /**
   * Current user.
   * ```
   * ['users', 'myself']
   * ```
   */
  myself: () => [...users.all(), 'myself'] as const,
}
