import { WretchError } from 'wretch'

type NestJsErrorJsonShape = { message: string }

// Used to check if an the json object of an error message is of standardised NestJS Format
export const isNestJsErrorJson = (
  errorJson: unknown,
): errorJson is NestJsErrorJsonShape => {
  return (errorJson as NestJsErrorJsonShape).message !== undefined
}

export const isWretchError = (error: unknown): error is WretchError => {
  const coerced = error as WretchError

  return (
    coerced.status !== undefined &&
    coerced.name !== undefined &&
    coerced.message !== undefined &&
    coerced.response !== undefined
  )
}
