export const recordings = {
  /**
   * All recordings
   * ```
   * ['recordings']
   * ```
   */
  all: () => ['recordings'] as const,
  /**
   * Factories for a recording
   */
  recording: (id: number) => ({
    /**
     * All query keys for a recording
     * ```
     * ['recordings', 1]
     * ```
     */
    all: () => [...recordings.all(), id],
    /**
     * All summaries for a recording
     * ```
     * ['recordings', 1, 'summaries']
     * ```
     */
    summaries: () => [...recordings.recording(id).all(), 'summaries'],
  }),

  /**
   * Last summary customisation
   */
  lastCustomisation: () => ['last-customisation'] as const,

  /**
   * Previous non-favourite summaries
   */
  previousSummaries: () => ['previous-summaries'] as const,

  /**
   * Favourited summaries
   */
  favouriteSummaries: () => ['favourite-summaries'] as const,
}
