import { Link as ReactLink } from 'react-router-dom'
import { Link } from '@chakra-ui/react'

interface NavBackLinkProps {
  to: string
  text: string
}

export const NavBackLink = ({ to, text }: NavBackLinkProps) => {
  return (
    <Link variant="standalone" as={ReactLink} to={to}>
      {text}
    </Link>
  )
}
