export const appConfig = {
  /**
   * All app configurations.
   * ```
   * ['app-config']
   * ```
   */
  all: () => ['app-config'],

  /**
   * Banner.
   * ```
   * ['app-config', 'banner']
   * ```
   */
  banner: () => [...appConfig.all(), 'banner'],

  /**
   * Institution feature flags.
   * ```
   * ['app-config', 'featureFlags']
   * ```
   */
  featureFlags: () => [...appConfig.all(), 'featureFlags'],

  /**
   * Vendor Users.
   * ```
   * ['app-config', 'vendor-users']
   * ```
   */
  vendorUsers: () => [...appConfig.all(), 'vendor-users'],
}
