import z from 'zod'

import { createZodDto } from './dto'

export enum SummaryStatus {
  INITIALISED = 'initialised',
  READY = 'ready',
  FAILED = 'failed',
}

export const getSummarySchema = z.object({
  id: z.number(),
  authorId: z.number(),
  recordingId: z.number(),
  status: z.nativeEnum(SummaryStatus),
  content: z.string().nullable(),
  errorMessage: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export class GetSummaryDto extends createZodDto(getSummarySchema) {}

export const getPreviousSummarySchema = z.object({
  id: z.number(),
  isFavourite: z.boolean(),
  title: z.string(),
  description: z.string().nullable(),
  headers: z.string().nullable(),
  writingStyle: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export class GetPreviousSummaryDto extends createZodDto(
  getPreviousSummarySchema,
) {}

const WRITING_STYLES = ['Point form', 'Paragraphs'] as const
export type WritingStyle = (typeof WRITING_STYLES)[number]

export const createSummarySchema = z.object({
  recordingId: z.number(),
  description: z.string(),
  headers: z.array(z.string()),
  writingStyle: z.enum(WRITING_STYLES),
})

export class CreateSummaryDto extends createZodDto(createSummarySchema) {}

export const getLastCustomisationSchema = z.object({
  description: z.string().nullable(),
  headers: z.string().nullable(),
  writingStyle: z.string().nullable(),
})

export class GetLastCustomisationDto extends createZodDto(
  getLastCustomisationSchema,
) {}

export const updateFavouriteSchema = z.object({
  isFavourite: z.boolean().optional(),
  title: z.string().optional(),
})

export class UpdateFavouriteDto extends createZodDto(updateFavouriteSchema) {}
