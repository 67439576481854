import { useCallback } from 'react'
import { BiCopy, BiInfoCircle, BiTrash } from 'react-icons/bi'
import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { GetSummaryDto, SummaryStatus } from '@shared/types/summary'
import { fmtDateTime } from '@shared/utils/fmtDatetime'

import { useDeleteSummaryMutation } from '~/hooks/recordings'
import { useCopyAndToast } from '~/hooks/useCopyAndToast'
import { DeleteSummaryModal } from '~/pages/Summary/DeleteSummaryModal'

export const SummaryCard = ({ summary }: { summary: GetSummaryDto }) => {
  const { recordingId, id: summaryId, createdAt, content, status } = summary
  const copyAndToast = useCopyAndToast()
  const {
    isOpen: isDeleteSummaryOpen,
    onOpen: onDeleteSummaryOpen,
    onClose: onDeleteSummaryClose,
  } = useDisclosure()

  const { mutateAsync: deleteSummary, isPending: isDeleteSummaryLoading } =
    useDeleteSummaryMutation({
      recordingId,
      summaryId,
    })

  const handleDeleteSummary = useCallback(async () => {
    await deleteSummary()
    onDeleteSummaryClose()
  }, [deleteSummary, onDeleteSummaryClose])

  return (
    <>
      <DeleteSummaryModal
        isOpen={isDeleteSummaryOpen}
        onClose={onDeleteSummaryClose}
        onDelete={handleDeleteSummary}
        isLoading={isDeleteSummaryLoading}
      />
      <VStack
        p={4}
        spacing={0}
        backgroundColor="white"
        boxShadow="sm"
        alignItems="start"
        width="full"
        rounded={'md'}
      >
        <HStack w={'full'} justify={'space-between'}>
          <HStack>
            <Text textStyle={'h6'}>Summary</Text>
            <Badge colorScheme={'sub'} variant="subtle">
              <HStack spacing={1}>
                <BiInfoCircle />
                <Text>AI-generated</Text>
              </HStack>
            </Badge>
          </HStack>

          <HStack spacing={1}>
            <Tooltip placement={'bottom'} hasArrow label={'Copy summary'}>
              <IconButton
                variant="clear"
                aria-label="copy summary"
                icon={<BiCopy />}
                onClick={async (e) => {
                  e.preventDefault()
                  await copyAndToast(content ?? '')
                }}
              />
            </Tooltip>
            <IconButton
              variant="clear"
              aria-label="delete summary"
              icon={<BiTrash />}
              colorScheme="red"
              onClick={onDeleteSummaryOpen}
            />
          </HStack>
        </HStack>
        <Text textStyle="body-2" color="grey.500">
          Generated at {fmtDateTime(createdAt)}
        </Text>
        <Box pt={2} w={'full'}>
          {status === SummaryStatus.INITIALISED && (
            <Flex w={'full'} justify={'center'} py={2}>
              <Spinner />
            </Flex>
          )}
          {status === SummaryStatus.READY && (
            <Text whiteSpace={'pre-line'} wordBreak="break-word">
              {content}
            </Text>
          )}
          {status === SummaryStatus.FAILED && (
            <Flex w={'full'} justify={'center'} py={2}>
              <Text fontStyle={'italic'}>
                Failed to summarise transcript. Please try again.
              </Text>
            </Flex>
          )}
        </Box>
      </VStack>
    </>
  )
}
