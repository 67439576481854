import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

export const DeleteRecordingModal = ({
  isOpen,
  onClose,
  onDelete,
  isLoading,
}: {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  isLoading: boolean
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Session?</ModalHeader>
        <ModalBody>
          <Text textStyle="body-1">
            You will lose all transcripts and summaries generated from this
            session.
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="clear" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={onDelete}
              colorScheme={'critical'}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
