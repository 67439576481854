import { Flex, FlexProps, Spinner } from '@chakra-ui/react'

export const Loading = ({
  fullscreen = false,
  flexProps,
}: {
  fullscreen?: boolean
  flexProps?: FlexProps
}) => (
  <Flex
    w="100%"
    h={fullscreen ? '100vh' : 200}
    justify="center"
    align="center"
    {...flexProps}
  >
    <Spinner />
  </Flex>
)
