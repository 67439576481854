import { Banner as DSBanner } from '@opengovsg/design-system-react'
import { BannerLevel } from '@shared/types/app-config'

import { useBanner } from '~/hooks/app-config'

export const Banner = () => {
  const banner = useBanner({ suspense: false })

  return banner?.data ? (
    <DSBanner
      isDismissable={false}
      variant={banner?.bannerLevel || BannerLevel.INFO}
    >
      {banner.data}
    </DSBanner>
  ) : null
}
