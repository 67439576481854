import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Textarea } from '@opengovsg/design-system-react'
import { GetPreviousSummaryDto, WritingStyle } from '@shared/types/summary'

import {
  useCreateSummaryMutation,
  useFavouriteSummaries,
  usePreviousSummaries,
} from '~/hooks/recordings'
import { useToast } from '~/hooks/useToast'

import {
  CreateSummaryFormState,
  DEFAULT_DESCRIPTION,
  DEFAULT_HEADERS,
  DEFAULT_WRITING_STYLE,
  TemplateMenuItem,
} from './CreateSummaryModal.helpers'

export const CreateSummaryModal = ({
  isOpen,
  onClose,
  recordingId,
}: {
  isOpen: boolean
  onClose: () => void
  recordingId: number
}) => {
  const previousSummaries = usePreviousSummaries()
  const favouriteSummaries = useFavouriteSummaries()

  const { control, handleSubmit, setValue } = useForm<CreateSummaryFormState>({
    defaultValues: {
      description: DEFAULT_DESCRIPTION,
      headers: DEFAULT_HEADERS,
      writingStyle: DEFAULT_WRITING_STYLE,
    },
  })

  const loadSummaryCustomisation = useCallback(
    (summary: GetPreviousSummaryDto) => {
      if (summary.description !== null) {
        setValue('description', summary.description)
      }
      if (summary.headers !== null) {
        setValue('headers', summary.headers)
      }
      if (summary.writingStyle !== null) {
        setValue('writingStyle', summary.writingStyle)
      }
    },
    [setValue],
  )

  const toast = useToast()
  const { mutateAsync: createSummary, isPending: isCreateSummaryLoading } =
    useCreateSummaryMutation({
      recordingId,
      onSuccess() {
        toast({ description: 'Summary request submitted!', status: 'success' })
        onClose()
      },
      onError() {
        toast({
          description: 'Summary request failed, please try again',
          status: 'error',
        })
      },
    })

  const onSubmit = useCallback(
    async (values: CreateSummaryFormState) => {
      await createSummary({
        description: values.description,
        headers: values.headers
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line.length > 0),
        writingStyle: values.writingStyle as WritingStyle,
      })
    },
    [createSummary],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} closeOnOverlayClick>
      <ModalOverlay />
      <ModalContent p={0} marginY={'3.75rem'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody p={0} pr={2}>
            <HStack h="100%">
              <VStack
                w={'300px'}
                pr={4}
                pl={6}
                py={8}
                justify="flex-start"
                align={'flex-start'}
                bg="gray.100"
                flexDir={'column'}
                alignSelf={'stretch'}
                rounded={'md'}
                spacing={2}
              >
                <Flex>
                  <Text textStyle={'h6'} fontWeight={'semibold'}>
                    Generate Summary
                  </Text>
                </Flex>
                <VStack
                  w="full"
                  h="500px"
                  overflowY={'auto'}
                  align={'flex-start'}
                  spacing={1}
                >
                  <TemplateMenuItem
                    summary={
                      {
                        description: DEFAULT_DESCRIPTION,
                        headers: DEFAULT_HEADERS,
                        writingStyle: DEFAULT_WRITING_STYLE,
                      } as GetPreviousSummaryDto
                    }
                    onClick={loadSummaryCustomisation}
                    fixedTitle={'New (Default)'}
                  />
                  <VStack spacing={3} w="full">
                    <VStack spacing={1} align={'flex-start'} w="full">
                      <Text textStyle={'subhead-3'}>Favourites</Text>
                      {favouriteSummaries.map((summ) => (
                        <TemplateMenuItem
                          key={summ.id}
                          summary={summ}
                          onClick={loadSummaryCustomisation}
                        />
                      ))}
                    </VStack>
                    <VStack spacing={1} align={'flex-start'} w="full">
                      <Text textStyle={'subhead-3'}>Recently used</Text>
                      {previousSummaries.map((summ) => (
                        <TemplateMenuItem
                          key={summ.id}
                          summary={summ}
                          onClick={loadSummaryCustomisation}
                        />
                      ))}
                    </VStack>
                  </VStack>
                </VStack>
              </VStack>
              <VStack px={4} py={8} w="full">
                <VStack spacing={4} w="full">
                  <Controller
                    name={'description'}
                    control={control}
                    rules={{
                      required: 'You must provide a description',
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FormControl isInvalid={!!error}>
                        <VStack align={'left'} spacing={0}>
                          <HStack spacing={0} mb={'0.75rem'}>
                            <FormLabel mb={0}>Description</FormLabel>
                            <FormHelperText>
                              Identifying participants helps Scribe generate a
                              more accurate summary
                            </FormHelperText>
                          </HStack>
                          <Textarea
                            placeholder={'I am a social worker meeting...'}
                            value={value}
                            minAutosizeRows={2}
                            maxAutosizeRows={3}
                            onChange={onChange}
                          />{' '}
                        </VStack>
                        {error && (
                          <FormErrorMessage>{error.message}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'headers'}
                    control={control}
                    rules={{
                      required: 'You must provide at least one header',
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FormControl isInvalid={!!error}>
                        <VStack align={'left'} spacing={0}>
                          <HStack spacing={0} mb={'0.75rem'}>
                            <FormLabel mb={0}>Headers</FormLabel>
                            <FormHelperText>
                              Configure the headers for your summary. Put each
                              header on a new line.{' '}
                            </FormHelperText>
                          </HStack>
                          <Textarea
                            placeholder={'Header 1\nHeader 2\nHeader 3'}
                            value={value}
                            onChange={onChange}
                            minAutosizeRows={8}
                            maxAutosizeRows={12}
                          />{' '}
                        </VStack>
                        {error && (
                          <FormErrorMessage>{error.message}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'writingStyle'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <HStack w={'full'}>
                        <Text textStyle={'subhead-1'}>Writing style:</Text>
                        <RadioGroup value={value} onChange={onChange}>
                          <HStack spacing={5} w={'full'}>
                            <Radio value="Paragraphs">Paragraphs</Radio>
                            <Radio value={'Point form'}>
                              <Text w={'max-content'}>Point form</Text>
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      </HStack>
                    )}
                  />
                </VStack>
                <VStack w={'full'} spacing={4} pt={2}>
                  <Text fontSize={'sm'}>
                    Scribe uses an AI large language model. Any content it
                    generates may contain errors, inconsistencies, or outdated
                    information. Please fact-check and verify all AI-generated
                    content before use.
                  </Text>
                  <HStack spacing={4} w={'full'} justify={'right'}>
                    <Button variant="clear" onClick={onClose}>
                      Back to session
                    </Button>
                    <Button type={'submit'} isLoading={isCreateSummaryLoading}>
                      Generate
                    </Button>
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  )
}
