import { Suspense } from 'react'
import { Center, Spinner, VStack } from '@chakra-ui/react'

import { Navbar } from '~/components/Navbar'
import { RecordingsList } from '~/pages/Recordings/RecordingsList'

export const Recordings = () => {
  return (
    <VStack alignItems="left" spacing={0} h={'100vh'}>
      <Navbar />
      <Suspense
        fallback={
          <Center height="full">
            <Spinner />
          </Center>
        }
      >
        <RecordingsList />
      </Suspense>
    </VStack>
  )
}
