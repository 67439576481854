import { ReactNode } from 'react'
import { Image, Text, VStack } from '@chakra-ui/react'

import GenericEmptySvg from '../assets/icons/generic-empty.svg'

type EmptyProps = {
  svg?: string
  title?: string
  description?: string
  size?: 'sm' | 'lg'
  children?: ReactNode
}

export const Empty = ({
  svg = GenericEmptySvg,
  title,
  description,
  size = 'sm',
  children,
}: EmptyProps) => {
  return (
    <VStack w="full" py={12}>
      <Image src={svg} alt="envelope" />
      <Text textStyle={size === 'sm' ? 'subhead-1' : 'h5'}>
        {title || 'No Records'}
      </Text>
      {description && (
        <Text textStyle={size === 'sm' ? 'caption-2' : 'body-1'}>
          {description}
        </Text>
      )}
      {children}
    </VStack>
  )
}
