import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from '~/app'

import { initDatadog } from './utils/datadog'

if (import.meta.env.PROD) {
  initDatadog()
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
