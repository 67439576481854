import { BiInfoCircle } from 'react-icons/bi'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

export const ViewTranscriptModal = ({
  isOpen,
  onClose,
  transcript,
}: {
  transcript: string | null
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
      <ModalOverlay />
      <ModalContent borderRadius={'0.375rem'} maxW={920} height={'70%'}>
        <ModalHeader>Transcript</ModalHeader>
        <ModalBody overflowY={'auto'}>
          <VStack
            alignItems="start"
            width="full"
            rounded={'md'}
            overflowY={'auto'}
          >
            {transcript === null ? (
              <Text textStyle="subhead-2">Transcription in progress</Text>
            ) : (
              <>
                <HStack textStyle="body-2" color="grey.500">
                  <BiInfoCircle />
                  <Text>
                    Scribe makes use of a machine-learning model for
                    transcription. Check your transcripts for accuracy.
                  </Text>
                </HStack>
                <Text whiteSpace={'pre-line'} wordBreak="break-word">
                  {transcript}
                </Text>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="clear" onClick={onClose}>
            Back to session
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
