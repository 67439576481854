import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { UNAUTHORIZED_EVENT } from '~/constants/events'
import { useToast } from '~/hooks/useToast'
import { routes } from '~/routes'

export const Auth = ({ children }: { children: ReactNode }) => {
  const toast = useToast()
  const qc = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  /**
   * This function is called when the API client receives an HTTP Unauthorized response from the backend.
   * Clear the react-query cache for safe measure, then redirect to log in.
   */
  const handleUnauthorizedEvent = useCallback(() => {
    const toastId = 'logged-out-toast'
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        status: 'warning',
        description: 'You are not logged in. Please log in to continue.',
      })
    }
    qc.clear()
    navigate({
      pathname: routes.login,
      search:
        location.pathname === routes.dashboard ||
        location.pathname === routes.login
          ? undefined
          : `?redirect=${location.pathname}`,
    })
  }, [toast, qc, navigate, location.pathname])

  // Listen for events, handle accordingly
  useEffect(() => {
    window.addEventListener(UNAUTHORIZED_EVENT, handleUnauthorizedEvent)
    setLoading(false)
    return () => {
      window.removeEventListener(UNAUTHORIZED_EVENT, handleUnauthorizedEvent)
    }
  }, [handleUnauthorizedEvent])

  if (loading) return null

  return <>{children}</>
}
