import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Stack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  Infobox,
  Input,
} from '@opengovsg/design-system-react'
import { OTP_LENGTH } from '@shared/constants/auth'
import { EmailVerifyOtpDto } from '@shared/types/auth'
import { useQueryClient } from '@tanstack/react-query'
import { useInterval } from 'usehooks-ts'

import { queryKeys } from '~/constants/query-keys'
import { useLogin, useVerifyOtp } from '~/hooks/auth'
import { useLoginState } from '~/hooks/auth/LoginStateContext'

import { useSignInContext } from '../SignInContext'

import { ResendOtpButton } from './ResendOtpButton'

export const VerificationInput = () => {
  const [showOtpDelayMessage, setShowOtpDelayMessage] = useState(false)
  const { setHasLoginStateFlag } = useLoginState()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { vfnStepData, timer, setVfnStepData, resetTimer } = useSignInContext()

  useInterval(
    () => setShowOtpDelayMessage(true),
    // Show otp delay info message after 15 seconds.
    showOtpDelayMessage ? null : 15000,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
    setFocus,
    setError,
  } = useForm<EmailVerifyOtpDto>({
    defaultValues: {
      email: vfnStepData?.email ?? '',
      token: '',
    },
  })

  const verifyOtpMutation = useVerifyOtp({
    onSuccess: async () => {
      setHasLoginStateFlag()
      await queryClient.invalidateQueries({
        queryKey: queryKeys.users.myself(),
      })
      navigate('/recordings')
    },
    onError: (err) => {
      // TODO (Caleb) make this less jank
      const message = (err.json as { message: string })['message']
      switch (message) {
        case 'Token is invalid or has expired':
          setError('token', {
            message:
              'This OTP is invalid or has expired, click resend OTP to get a new one',
          })
          break
        case 'Too many attempts':
          setError('token', {
            message:
              'You have attempted the wrong OTP too many times, click resend OTP to get a new one',
          })
          break
        case 'User account disabled':
          setError('token', {
            message:
              'Your account has been disabled. Please contact your organisation if this is a mistake.',
          })
          break
        default:
          setError('token', { message: 'Invalid organisation email' })
      }
    },
  })

  const resendOtpMutation = useLogin({
    onError: (error) => setError('token', { message: error.message }),
  })

  const handleVerifyOtp = handleSubmit(({ email, token }) => {
    return verifyOtpMutation.mutate({ email, token })
  })

  const handleResendOtp = () => {
    if (timer > 0 || !vfnStepData?.email) return
    return resendOtpMutation.mutate(
      { email: vfnStepData.email },
      {
        onSuccess: ({ email, otpPrefix }) => {
          setVfnStepData({ email, otpPrefix })
          resetField('token')
          setFocus('token')
          // On success, restart the timer before this can be called again.
          resetTimer()
        },
      },
    )
  }

  if (!vfnStepData) return null

  return (
    <form onSubmit={handleVerifyOtp}>
      <Stack direction="column" spacing="1rem">
        <FormControl
          id="email"
          isInvalid={!!errors.token}
          isReadOnly={verifyOtpMutation.isPending}
        >
          <FormLabel htmlFor="email">
            Enter the OTP sent to {vfnStepData.email}
          </FormLabel>
          <Controller
            control={control}
            name="token"
            render={({ field: { onChange, value, ...field } }) => (
              <InputGroup>
                <InputLeftAddon>{vfnStepData?.otpPrefix}-</InputLeftAddon>
                <Input
                  autoFocus
                  autoCapitalize="true"
                  autoCorrect="false"
                  autoComplete="one-time-code"
                  placeholder="ABC123"
                  maxLength={OTP_LENGTH}
                  {...field}
                  value={value}
                  onChange={(e) => onChange(e.target.value.toUpperCase())}
                />
              </InputGroup>
            )}
          />
          <FormErrorMessage>{errors.token?.message}</FormErrorMessage>
        </FormControl>
        <Stack direction="column" spacing="0.75rem">
          <Button
            size="xs"
            height="2.75rem"
            type="submit"
            // Want to keep loading state until redirection is complete.
            isLoading={
              verifyOtpMutation.isPending || verifyOtpMutation.isSuccess
            }
          >
            Sign in
          </Button>
          {showOtpDelayMessage && (
            <Infobox>
              OTP might be delayed due to government email traffic. Try again
              later.
            </Infobox>
          )}
          <ResendOtpButton
            alignSelf="end"
            timer={timer}
            onClick={handleResendOtp}
            isDisabled={timer > 0 || verifyOtpMutation.isPending}
            isLoading={resendOtpMutation.isPending}
            spinnerFontSize="1rem"
            _loading={{
              justifyContent: 'flex-end',
            }}
          />
        </Stack>
      </Stack>
    </form>
  )
}
